import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography, InputAdornment, IconButton, Tooltip } from '@mui/material'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DateTime } from "luxon";
import MUIRichTextEditor from "mui-rte";
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { AmountFormat, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../../Components/Loader';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { getCachevalue, localGetItem, setCachevalue } from '../../../../Utility/Services/CacheProviderService';
import ReservationList from './ReservationList';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { CurrencySymbol } from '../../../../Utility/Constants';

class IPAddmissionDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IsMedicoLegal: false,
            PatientWardList: [],
            PatientBlockList: [],
            PatientFloorList: [],
            PatientBedList: [],
            PatientRoomList: [],
            PatientWard: "",
            PatientBlock: "",
            PatientFloor: "",
            PatientRoom: "",
            PatientBed: "",
            PatientFloorId: "",
            PatientBederror: false,
            PatientBedtext: "",

            AttenderWardList: [],
            AttenderBlockList: [],
            AttenderFloorList: [],
            AttenderBedList: [],
            AttenderRoomList: [],
            AttenderWard: "",
            AttenderBlock: "",
            AttenderFloor: "",
            AttenderRoom: "",
            AttenderBed: "",
            AttenderFloorId: "",
            AttenderBederror: false,
            AttenderBedtext: "",

            AddmissionDateTime: new Date(),
            DocList: [],
            Doctor: "",
            CoConsultant: "",
            NurseList: [],
            Nurse: "",
            TagList: [],
            Tag: "",
            VisitConsult1: "",
            VisitConsult2: "",
            VisitConsult3: "",
            VisitConsult4: "",
            SurgeonName: "",
            Complaints: this.props.history?.location?.state?.SelectedPatientDetails?.complaints ? this.props.history?.location?.state?.SelectedPatientDetails?.complaints : "",
            MedicoDate: null,
            MedicoInformer: "",
            MedicoLocation: "",
            MedicoPoliceStation: "",
            MedicoComments: "",
            AttenderName: "",
            AttenderMobileNo: "",
            AttenderRelation: "",
            AttenderLocation: "",
            ConsentForAdmissionText: '',
            RestraintConsentText: '',
            PatientRoomType: "hourly",
            AttenderRoomType: "hourly",
            PatientDetails: this.props.PatientDetails,

            WardList: [],
            BackBtnClicked: false,
            DateOpen: false,
            PatientBedObj: {},
            ComplaintsList: localGetItem("ipComplaintsDataList") === null ? [] : JSON.parse(localGetItem("ipComplaintsDataList")),
            CreditLmt: 0,
            PharmaCreditLmt:0,
            ReservationListPopup: null,
            PatientReservedData: {},
            SelectedDoctor:[],
            attrnder_Data: {},
            patient_Data: {},
            bed_ID: null,
            attender_bed_ID: null,
            LastVisitObject : {},

            selectedPaymentType:'Patient credit',
            CorporateList:[],
            InsuranceList:[],
            selectedCorporate:null,
            selectedInsurance:null,
            selectedInsuranceNumber:'',
            selectedCorporateNumber:'',
            selectedCorporateId:null,
            policyStatus: false,
            clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId'))
        }
    }
    componentDidMount() {
        this.GetIntialDatas()
        this.getInsuranceList()
        this.GetCorporateList()
        this.GetRoomListData(new Date())
        if (Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.isfrom_Patientdetails || this.props.is_From_Ip_AdmissionDetails) {
            this.getAdmissionData()
            this.GetPolicyStatus()
        }
        //  else {
        //     this.GetRoomListData(new Date())
        // }
        else if (this.props.PatientDetails?.patient_id){
            this.getPatientDetails(this.props.PatientDetails?.patient_id)
            this.getLastVisitDateOutstandingAmount()
        }
    }

    getPatientDetails(PatientId){
        try{
            RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + "?patient_id=" + PatientId).
            then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        PatientDetails: {...response.data.data, ...this.state.PatientDetails},
                    }, () => {
                        this.setState({
                            selectedCorporate: this.state.PatientDetails?.company_name,
                            selectedCorporateNumber: this.state.PatientDetails?.id_no,
                            selectedCorporateId: this.state.PatientDetails?.employer_id,
                            selectedInsurance: this.state.PatientDetails?.insurance_company_name,
                            selectedInsuranceNumber: this.state.PatientDetails?.policy_number
                        })
                    })
                }
                else {
                    this.errorMessage(response.data.message)
                }
            }).catch((error) => {
                this.errorMessage(error.message)
            })
        }
        catch(error){
            this.errorMessage(error.message)
        }
    }
    getAdmissionData() {
        try {
            let IpPatientDetails = getCachevalue('IppatientData')
            let patientData = JSON.parse(IpPatientDetails)
            var id = this.props.bacKfromBilling?.ip_admission_id ? this.props.bacKfromBilling?.ip_admission_id : patientData?.id
            let url = `${Serviceurls.IP_ADMISSION_DETAILS}?ip_admission_id=${id}`;
            if(this.props.is_From_Ip_AdmissionDetails && this.state.clinic_id){
                url = `${url}&clinic_id=${this.state.clinic_id}`
            }
            this.lodaerFunction(true)
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        this.AdmissionDatas(response.data.data)
                        if(this.props?.bacKfromBilling?.patient_id){
                            this.getPatientDetails(this.props.bacKfromBilling?.patient_id)
                            this.getLastVisitDateOutstandingAmount(id, this.props?.bacKfromBilling?.patient_id)
                        }
                        if (this.props.isfrom_Patientdetails || this.props.is_From_Ip_AdmissionDetails) {
                            let Data = {
                                photo_url: patientData?.patient_photo_url,
                                first_name: patientData?.patient_name,
                                patient_account_number: patientData?.patient_account_number,
                                mobile_number: patientData?.patient__mobile_number,
                                dob: patientData?.patient__dob,
                                patient_id: patientData?.patient_id,
                                patient_gender: patientData?.patient_gender,

                            }
                            this.setState({
                                PatientDetails: Data
                            })
                        }
                        this.lodaerFunction(false)
                    }
                    else {
                        this.lodaerFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.lodaerFunction(false)
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetCorporateList() {
        try {
          RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE).
            then((response) => {
              if (response.data.status === "success") {
                this.setState({
                  CorporateList: response.data.data
                })
              }
            }).catch((error) => {
                if(error?.response?.data?.message){
                    this.errorMessage(error.response.data.message)
                }else{
                    this.errorMessage(error.message)
                }
            })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
      }

      getInsuranceList = () => {
        try {
          RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST).
            then((response) => {
              if (response.data.status === "success") {
                this.setState({
                  InsuranceList: response.data.data,
                })
              }
            }).catch((error) => {
                if(error?.response?.data?.message){
                    this.errorMessage(error.response.data.message)
                }else{
                    this.errorMessage(error.message)
                }
            })
        } catch (e) {
          this.errorMessage(e.message)
        }
      }

    GetPolicyStatus = () => {
        try {
            let IpPatientDetails = getCachevalue('IppatientData')
            let patientData = JSON.parse(IpPatientDetails)
            var id = this.props.bacKfromBilling?.ip_admission_id ? this.props.bacKfromBilling?.ip_admission_id : patientData?.id
            let url = `${Serviceurls.IP_PATIENT_DETAILS}?admission_id=${id}`;
            if(this.props.is_From_Ip_AdmissionDetails && this.state.clinic_id){
                url = `${url}&clinic_id=${this.state.clinic_id}`
            }
            this.lodaerFunction(true)
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            policyStatus: response.data.data[0].is_final_bill_generated ? true : false,
                        },() => {
                            this.lodaerFunction(false)
                        })
                    }
                    else {
                        this.lodaerFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.lodaerFunction(false)
                    this.errorMessage(error.message)
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    lodaerFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message,
            DisableBtn : false
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    AdmissionDatas(Datas) {
        try {
            this.setState({
                attrnder_Data: Datas?.attender_config_id,
                patient_Data: Datas?.patient_config_id,
                Doctor: Datas?.doctor_id,
                CoConsultant: Datas?.co_consultant_id,
                Nurse: Datas?.nurse_id,
                Tag: Datas?.patient_tags_id,
                VisitConsult1: Datas?.visiting_doctor_id ? Datas?.visiting_doctor_id : Datas?.visiting_consultant,
                VisitConsult2: Datas?.visiting_doctor_2 ? Datas?.visiting_doctor_2 : Datas?.visiting_consultant_2,
                VisitConsult3: Datas?.visiting_doctor_3 ? Datas?.visiting_doctor_3 : Datas?.visiting_consultant_3,
                VisitConsult4: Datas?.visiting_doctor_4 ? Datas?.visiting_doctor_4 : Datas?.visiting_consultant_4,
                SurgeonName: Datas?.surgeon_name,
                Complaints: Datas?.complaints,
                IsMedicoLegal: Datas?.medico_legal_case,
                MedicoInformer: Datas?.informer_name,
                MedicoLocation: Datas?.incident_location,
                MedicoPoliceStation: Datas?.police_station,
                MedicoComments: Datas?.medico_legal_comments,
                AttenderName: Datas?.attender_name,
                AttenderMobileNo: Datas?.attender_mobile,
                AttenderRelation: Datas?.attender_relationship,
                AttenderLocation: Datas?.attender_location,
                ConsentForAdmissionText: Datas?.consent_for_admission,
                RestraintConsentText: Datas?.restaint_Consent,
                PatientWard: Datas?.patient_config_id?.patinet_ward_name,
                PatientBlock: Datas?.patient_config_id?.patinet_block_name,
                PatientFloor: Datas?.patient_config_id?.patinet_floor,
                PatientRoom: Datas?.patient_config_id?.patinet_room_number,
                PatientBed: Datas?.patient_config_id?.patinet_bed_number,
                AttenderWard: Datas?.attender_config_id?.attender_ward_name,
                AttenderBlock: Datas?.attender_config_id?.attender_block_name,
                AttenderFloor: Datas?.attender_config_id?.attender_floor,
                AttenderRoom: Datas?.attender_config_id?.attender_room_number,
                AttenderBed: Datas?.attender_config_id?.attender_bed_number,
                PatientRoomType: (Datas?.patient_config_id?.is_hourly && Datas?.patient_config_id?.room_booked_is_hour) ? "hourly" : "day",
                CreditLmt: Datas?.ip_admission_credit_limit,
                PharmaCreditLmt: Datas?.ip_pharmacy_credit_limit,
                MedicoDate: Datas?.incident_date === null ? null : new Date(Datas?.incident_date),
                PatientBedObj: Datas?.patient_config_id,
                bed_ID: Datas?.patient_config_id?.patinet_bed_id,
                attender_bed_ID: Datas?.attender_config_id?.attender_bed_id,
                selectedCorporate:Datas?.corporate_name ? Datas?.corporate_name : null,
                selectedCorporateNumber:Datas?.id_no ? Datas?.id_no : '',
                selectedInsurance:Datas?.insurance_company_name ? Datas?.insurance_company_name : null,
                selectedInsuranceNumber:Datas?.policy_number ? Datas?.policy_number : '',
                selectedPaymentType:Datas?.credit_type === 'Corprate credit' ? "Corporate credit" : Datas?.credit_type ? Datas?.credit_type  : "Patient credit",
                selectedCorporateId:Datas?.employer_id ? Datas?.employer_id :'',
                AddmissionDateTime : new Date(`${Datas?.admission_date}T${Datas?.admission_time}`),
            })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    RemoveDuplicates(Array, key) {
        const FilteredArray = Array.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return FilteredArray
    }

    FloorCount(value) {
        const FloorCountList = [];
        for (let i = 1; i <= value; i++) { FloorCountList.push(i) }
        return FloorCountList;
    }
    WardSelectionMessage() {
        return (
            <Typography marginLeft={"1vw"} variant='body2' color={Colors.red}>{"Currently No Wards Available"}</Typography>
        )
    }
    BedSelectionMessage() {
        return (
            <Typography marginLeft={"1vw"} variant='body2' color={this.state.PatientBederror ? Colors.red : Colors.themeDark}>{this.state.PatientBedtext}</Typography>
        )
    }
    AttenderBedSelectionMessage() {
        return (
            <Typography marginLeft={"1vw"} variant='body2' color={this.state.AttenderBederror ? Colors.red : Colors.themeDark}>{this.state.AttenderBedtext}</Typography>
        )
    }
    SelectRentTypeRadio(DayRent, HourRent) {
        if (DayRent === true && (HourRent === undefined || HourRent === false)) {
            this.setState({ PatientRoomType: "day" })
        }
        else if ((DayRent === undefined || DayRent === false)  && HourRent === true) {
            this.setState({ PatientRoomType: "hourly" })
        }
        else if (DayRent === true && HourRent === true) {
            this.setState({ PatientRoomType: "hourly" })
        }
    }

    GetRoomListData(Date) {
        let IpPatientDetails = getCachevalue('IppatientData')
            let patientData = JSON.parse(IpPatientDetails)
            let ClinicID = this.state.PatientDetails.ClinicID ? this.state.PatientDetails.ClinicID : this.state.clinic_id;
        try {
            RestAPIService.getAll(Serviceurls.BED_SELECT+ "?clinic_id=" + ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            PatientWardList: this.RemoveDuplicates(response.data.data, "ward_id"),
                            AttenderWardList: this.RemoveDuplicates(response.data.data, "ward_id").filter((item) => (item.is_attender === true)),
                            WardList: response.data.data
                        }, () => {
                            let FormateDate = DateTime.fromJSDate(Date).toFormat("yyyy-MM-d")
                            RestAPIService.getAll(Serviceurls.FO_RESERVATION_FILTER_GET + "?patient_id=" + (this.state.PatientDetails.patient_id? this.state.PatientDetails.patient_id : patientData?.patient_id) + "&date=" + FormateDate).
                                then((Response) => {
                                    if (Response.data.status === "success") {
                                        let PatientReservedData = Response.data?.patient_list?.length > 0 ? Response.data.patient_list[0] : {}
                                        let AttenderReservedData = Response.data?.attender_list?.length > 0 ? Response.data.attender_list[0] : {}
                                        let ReservedBed = this.state.WardList.find((item) => (item.ward_id === PatientReservedData?.ward_id && (item.block_id === PatientReservedData?.block_no_id || PatientReservedData?.block_no_id === null) && (item.floor == PatientReservedData?.floor_number || PatientReservedData?.floor_number === null)))
                                        let AttenderReservedBed = this.state.WardList.find((item) => (item.ward_id === AttenderReservedData?.ward_id && (item.block_id === AttenderReservedData?.block_no_id || AttenderReservedData?.block_no_id === null) && (item.floor == AttenderReservedData?.floor_number || AttenderReservedData?.floor_number === null)))
                                        if (ReservedBed !== undefined) {
                                            this.setState({
                                                PatientWard: PatientReservedData?.ward_id,
                                                PatientBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === PatientReservedData?.ward_id)), "block_id"),
                                                PatientBlock:"",
                                                PatientFloorList:[],
                                                PatientFloor:"",
                                                PatientRoomList:[],
                                                PatientRoom:"",
                                                PatientBedList:[],
                                                PatientBed:""
                                            }, () => {
                                                if (PatientReservedData?.block_no_id !== null) {
                                                    this.setState({
                                                        PatientBlock: PatientReservedData?.block_no_id,
                                                        PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === PatientReservedData?.block_no_id)), "floor")
                                                    }, () => {
                                                        if (PatientReservedData?.floor_number !== null) {
                                                            this.setState({
                                                                PatientFloor: PatientReservedData?.floor_number,
                                                                PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == PatientReservedData?.floor_number)), "room_number")
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        } else {
                                            if (this.state.PatientWardList?.length == 1) {
                                                this.setState({
                                                    PatientWard: this.state.PatientWardList[0].ward_id,
                                                    PatientBlockList: this.RemoveDuplicates(this.state.WardList, "block_id")
                                                }, () => {
                                                    if (this.state.PatientBlockList?.length == 1) {
                                                        this.setState({
                                                            PatientBlock: this.state.PatientBlockList[0].block_id,
                                                            PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlockList[0].block_id)), "floor")
                                                        }, () => {
                                                            if (this.state.PatientFloorList?.length == 1) {
                                                                this.setState({
                                                                    PatientFloor: this.state.PatientFloorList[0].floor,
                                                                    PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloorList[0].floor)), "room_number")
                                                                }, () => {
                                                                    if (this.state.PatientRoomList?.length == 1) {
                                                                        this.setState({
                                                                            PatientRoom: this.state.PatientRoomList[0].room_number,
                                                                            PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                                                                        }, () => {
                                                                            if (this.state.PatientBedList?.length == 1) {
                                                                                this.setState({
                                                                                    PatientBed: this.state.PatientBedList[0].bed_id,
                                                                                    PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                                                                }, () => {
                                                                                    this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                        if(AttenderReservedBed !== undefined){
                                            this.setState({
                                                AttenderWard: AttenderReservedData?.ward_id,
                                                AttenderBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === AttenderReservedData?.ward_id)), "block_id"),
                                                AttenderBlock:"",
                                                AttenderFloorList:[],
                                                AttenderFloor:"",
                                                AttenderRoomList:[],
                                                AttenderRoom:"",
                                                AttenderBedList:[],
                                                AttenderBed:""
                                            }, () => {
                                                if (AttenderReservedData?.block_no_id !== null) {
                                                    this.setState({
                                                        AttenderBlock: AttenderReservedData?.block_no_id,
                                                        AttenderFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === AttenderReservedData?.block_no_id)), "floor")
                                                    }, () => {
                                                        if (AttenderReservedData?.floor_number !== null) {
                                                            this.setState({
                                                                AttenderFloor: AttenderReservedData?.floor_number,
                                                                AttenderRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == AttenderReservedData?.floor_number)), "room_number")
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    }
                                    else {
                                        this.errorMessage(response.data.message)
                                    }
                                }).catch((error) => {
                                    this.errorMessage(error.message)
                                })
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetIntialDatas() {
        try {
            let ClinicID = this.state.PatientDetails.ClinicID ? this.state.PatientDetails.ClinicID : this.state.clinic_id;
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + "?clinic_id=" + ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        let DocData = [] 
                        DocData = this.RemoveDuplicates(response.data.data, "doctor_name")
                        let docID = DocData.filter((item) => (item.doctor_id == this.state.PatientDetails?.DoctorID))
                        this.setState({
                            DocList: DocData.filter((item) => (item.specialization_active === true)),
                            Doctor: docID?.length > 0 ? docID[0]?.doctor_id : this.state.Doctor
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.FO_STAFF_LIST+ "?clinic_id=" + ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            NurseList: response.data.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })

            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPPATIENTTAG+ "?clinic_id=" + ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            TagList: response.data.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    getLastVisitDateOutstandingAmount = (AdmissionId = null, Patient_id = null) => {
        try {
            let APIURL = ""
            if (AdmissionId && Patient_id) {
                APIURL = "?patient_id=" + Patient_id + "&module=" + "IP" + "&branch_id=" + localGetItem("BranchId") + "&admission_id=" + AdmissionId
            } else {
                APIURL = "?patient_id=" + this.state.PatientDetails?.patient_id + "&module=" + "IP" + "&branch_id=" + localGetItem("BranchId")
            }
          RestAPIService.getAll(Serviceurls.FO_PATIENT_LAST_VISIT_DATE_AND_BALANCE_AMOUNT + APIURL).
            then((response) => {
              if (response.data.status === "success") {
                  let Date = response.data?.last_visited_date ? response.data.last_visited_date : ""
                  let Amount = response.data?.total_outstanding ? response.data.total_outstanding : 0
                  let AdvanceBalance = response.data?.advance_balance ? response.data.advance_balance : 0
                  let Obj = {"last_visited_date" : Date, "total_outstanding" : Amount, advance_balance : AdvanceBalance}
                  this.setState({
                    LastVisitObject : Obj
                  })
              }
              else {
                this.errorMessage(response.data.message)
              }
            }).catch((error) => {
              this.errorMessage(error.message)
            })
        } catch (e) {
          this.errorMessage(e.message)
        }
      }


    renderComponentRight() {
        const { t } = this.props
        let PatientName = this.state.PatientDetails?.first_name ? `${this.state.PatientDetails?.first_name ? this.state.PatientDetails?.first_name : ""} ${this.state.PatientDetails?.last_name ? this.state.PatientDetails?.last_name : ""} ` : this.state.PatientDetails?.patient_name
        let PatientAddress = `${(!this.state.PatientDetails?.address_line_1 || this.state.PatientDetails?.address_line_1 === "") ? "-" : this.state.PatientDetails?.address_line_1 + ","} ${(!this.state.PatientDetails?.address_line_2 || this.state.PatientDetails?.address_line_2 === "") ? "-" : this.state.PatientDetails?.address_line_2 + ","} ${(!this.state.PatientDetails?.city_name || this.state.PatientDetails?.city_name === "") ? "-" : this.state.PatientDetails?.city_name + ","} ${(!this.state.PatientDetails?.state_name || this.state.PatientDetails?.state_name === "") ? "-" : this.state.PatientDetails?.state_name + ","} ${!this.state.PatientDetails?.country || this.state.PatientDetails?.country === "" ? "-" : this.state.PatientDetails?.country + ","} ${!this.state.PatientDetails?.pincode || this.state.PatientDetails?.pincode === "" ? "-" : this.state.PatientDetails?.pincode + "."}`
        return (
            <Paper className='eMed_IPappoint_right'>
                <Typography className='eMed_IPappoint_title'>{t("PatientDetails")}</Typography>
                <Box component={'div'} className='eMed_Ipadmission_Avatar_box'>
                    <img className='eMed_Ipadmission_Avatar_img' src={(this.state.PatientDetails?.photo_url === null || this.state.PatientDetails?.photo_url === "") ? ImagePaths.Male.default : this.state.PatientDetails?.photo_url} alt={'Avatar'} />
                    <Box display={'flex'} flexDirection={"row"} marginY={"1vw"}>
                        <Typography>{PatientName}</Typography>{(this.state.PatientDetails?.patient_gender ? (this.state.PatientDetails?.patient_gender === "Male" ? <MaleIcon /> : <FemaleIcon />) : (this.state.PatientDetails?.gender === "m" ? <MaleIcon /> : <FemaleIcon />))}
                    </Box>
                    <Typography fontSize={"1vw"}>{`${this.state.PatientDetails.patient_account_number}`}</Typography>
                </Box>
                <Divider variant='middle' />
                <Box sx={{ height: "12vw", overflow: "scroll", width: "fit-content" }}>
                    <Box component={"div"} className='eMed_NxtAppPop_TextDiv' sx={{ marginTop: '1.5vw' }} >
                        <Box component={"div"} className="eMed_details_label">
                            <img src={ImagePaths.LabDateImage.default} alt="close" className='eMed_nxtAppoint_img_small' />
                            <Typography fontSize={"1vw"} marginLeft={"1vw"}>{this.state.PatientDetails?.dob ? formatDate(this.state.PatientDetails?.dob) : "-"}</Typography>
                        </Box>
                        <Box component={"div"} className="eMed_details_label">
                            {(this.state.PatientDetails?.patient_gender ? (this.state.PatientDetails?.patient_gender === "Male" ? <MaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : <FemaleIcon sx={{ fontSize: "1vw", color: '#616161' }} />) : (this.state.PatientDetails?.gender === "m" ? <MaleIcon sx={{ fontSize: "1vw", color: '#616161' }} /> : <FemaleIcon sx={{ fontSize: "1vw", color: '#616161' }} />))}
                            <Typography fontSize={"1vw"} marginLeft={"1vw"}>{this.state.PatientDetails?.patient_gender || (this.state.PatientDetails?.gender === 'm' ? "Male" : (this.state.PatientDetails?.gender === 'f' ? "Female" : "Trans"))}</Typography>
                        </Box>
                        <Box component={"div"} className="eMed_details_label">
                            <img src={ImagePaths.Phone.default} alt="close" className='eMed_nxtAppoint_img_small' />
                            <Typography fontSize={"1vw"} marginLeft={"0.5vw"}>{this.state.PatientDetails?.mobile_number ? this.state.PatientDetails?.mobile_number : "-"}</Typography>
                        </Box>
                    </Box>
                    {(this.props.isfrom_Patientdetails || this.state.PatientDetails?.fromOTHome || this.props.is_From_Ip_AdmissionDetails) ? null :
                        <Box component={"div"} className='eMed_NxtAppPop_TextDiv' sx={{ marginTop: '1vw' }}>
                            <Box component={"div"} className="eMed_details_label">
                                <img src={ImagePaths.DisabledHome.default} alt="close" className='eMed_nxtAppoint_img_small' />
                                {PatientAddress.length > 60 ?
                                    <Tooltip placement='top' title={PatientAddress}>
                                        <Typography fontSize={"0.9vw"} marginLeft={"1vw"}>{PatientAddress.slice(0, 60) + "..."}</Typography>
                                    </Tooltip> :
                                    <Typography fontSize={"0.9vw"} marginLeft={"1vw"}>{PatientAddress}</Typography>
                                }
                            </Box>
                        </Box>}

                    <Box sx={{ width: '19vw' }} display={Object.keys(this.state?.LastVisitObject).length > 0 ? "flex" : 'none'} flexDirection={'column'} marginLeft={"1vw"}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0.5vw' }}>
                            <EventRepeatIcon sx={{ fontSize: "0.9vw", marginRight: '1vw', marginLeft: "-0.2vw", color: '#616161' }} />
                            <Typography fontSize={"0.9vw"}>Last Admission date : </Typography>
                            <Typography fontWeight={600} marginLeft={"0.3vw"}>{this.state.LastVisitObject?.last_visited_date ? this.state.LastVisitObject?.last_visited_date : "-"}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0.5vw' }}>
                            <PaymentsIcon sx={{ fontSize: "0.9vw", marginRight: '1vw', marginLeft: "-0.2vw", color: '#616161' }} />
                            <Typography fontSize={"0.9vw"}>Previous Credit Balance : </Typography>
                            <Typography fontWeight={600} marginLeft={"0.3vw"}>{`${this.state.LastVisitObject?.total_outstanding ? AmountFormat(this.state.LastVisitObject?.total_outstanding) : AmountFormat(0)}`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0.5vw' }}>
                            <PaymentsIcon sx={{ fontSize: "0.9vw", marginRight: '1vw', marginLeft: "-0.2vw", color: '#616161' }} />
                            <Typography fontSize={"0.9vw"}>Previous Advance Balance : </Typography>
                            <Typography fontWeight={600} marginLeft={"0.3vw"}>{`${this.state.LastVisitObject?.advance_balance ? AmountFormat(this.state.LastVisitObject?.advance_balance) : AmountFormat(0)}`}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        )
    }

    renderTextBox(label, value, Key, maxLength, width, multiline, disable) {
        let states = this.state;
        return (
            <TextField
                disabled={disable}
                inputProps={{ maxLength: maxLength }}
                sx={{ width: width, marginTop: '0.8vw' }}
                size='small'
                label={label}
                value={value}
                onChange={(e) => {
                    if (Key === "AttenderMobileNo") {
                        let number = CommonValidation.numberOnly(e.target.value);
                        if (number || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({ states })
                        }
                    }
                    else if (Key === "MedicoComments" || Key === "Complaints" || Key === "MedicoPoliceStation") {
                        let alpha = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                        if (alpha || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({ states })
                        }
                    }
                    else {
                        let alpha = CommonValidation.alphabetOnly(e.target.value);
                        if (alpha || e.target.value === "") {
                            states[Key] = e.target.value
                            this.setState({ states })
                        }
                    }

                }}
                multiline={multiline}
                rows={4}
            />
        )
    }

    ComplaintsTextBox() {
        try {
            const { t } = this.props
            let states = this.state
            return (
                <Autocomplete
                    disabled={Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.isPatientDischarged || this.props.is_From_Ip_AdmissionDetails}
                    noOptionsText={states.Complaints === '' ? 'Please Search...' : 'No Data Found...'}
                    options={states.ComplaintsList}
                    getOptionLabel={(options) => options}
                    onChange={(e, value) => {
                        this.setState({
                            Complaints: value,
                        })
                    }}
                    clearIcon={false}
                    size='small'
                    sx={{ width: "28.2vw", marginTop: '0.8vw' }}
                    value={states.Complaints}
                    renderInput={(params) => <TextField sx={{ width: "28.2vw" }}
                        label="Reason / Diagnosis *"
                        value={states.Complaints}
                        onChange={(event) => {
                            // if (event.target.value.length < 60 || event.target.value === "") {
                            this.setState({
                                Complaints: event.target.value
                            })
                            // }
                        }}
                        {...params}
                    />}
                />)
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderDropdown(label, Key, List, LabelKey, ValueKey, disable = false, width) {
        let states = this.state;
        try {
            return (
                <Autocomplete
                    disabled={disable}
                    noOptionsText={'No Data Found...'}
                    options={List}
                    getOptionLabel={(options) => typeof(options) === "string" ? options : options[LabelKey]}
                    freeSolo={(states[Key] === "string") ? true : false}
                    onChange={(e, value) => {
                        if (value === null) {
                            states[Key] = ""
                            this.setState({ states })
                        } else {
                            if (Key === "CoConsultant" && states.Doctor === value[ValueKey]) {
                                this.errorMessage("Please select another doctor")
                            } else if (Key === "Doctor" && states.CoConsultant === value[ValueKey]) {
                                this.errorMessage("Please select another doctor")
                            } else if(states.Doctor === value[ValueKey] || states.CoConsultant === value[ValueKey] || states.VisitConsult1 === value[ValueKey] || states.VisitConsult2 === value[ValueKey] || states.VisitConsult3 === value[ValueKey] || states.VisitConsult4 === value[ValueKey]){
                                this.errorMessage("Please select another doctor")
                            } else {
                                states[Key] = value[ValueKey]
                                this.setState({ states })
                            }
                        }
                    }}
                    clearIcon={false}
                    size='small'
                    sx={{ width: width, marginTop: '0.8vw' }}
                    value={( typeof (states[Key]) === "string" ? states[Key] : (List?.length > 0 && states[Key]) ? List.find((item) => (item[ValueKey] === states[Key])) : null )}
                    renderInput={(params) => <TextField sx={{ width: width }} label={label} {...params}
                        onChange={(e) => {
                            if ((Key === "VisitConsult1" || Key === "VisitConsult2" || Key === "VisitConsult3" || Key === "VisitConsult4")) {
                                states[Key] = e.target.value
                                this.setState({ states })
                            }
                        }}
                    />}
                />
            )
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderDateTimePicker = (label, value, Key, width, disable) => {
        let states = this.state;
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    open={Key === "MedicoDate" ? null : this.state.DateOpen}
                    onOpen={() => { this.setState({ DateOpen: Key === "MedicoDate" ? this.state.DateOpen : true }) }}
                    onClose={() => { this.setState({ DateOpen: Key === "MedicoDate" ? this.state.DateOpen : false }) }}
                    label={label}
                    disabled={disable}
                    inputFormat='DD-MM-YYYY & hh:mmA'
                    value={value}
                    minDate={new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}
                    maxDateTime={new Date()}
                    onChange={(newDate) => {
                        if (newDate) {
                            states[Key] = newDate?.$d
                        }
                        else {
                            states[Key] = new Date()
                        }
                        this.setState({ states },()=>{if(Key === "AddmissionDateTime"){this.GetRoomListData(states[Key])}})
                    }}
                    renderInput={(params) => <TextField size='small'
                        // onKeyDown={(e) => e.preventDefault()}
                        onClick={() => { this.setState({ DateOpen: Key === "MedicoDate" ? this.state.DateOpen : true }) }}
                        sx={{ width: width, marginTop: '0.8vw', paddingLeft: 0 }}
                        label={label}
                        {...params}
                    />}
                />
            </LocalizationProvider>
        )
    }

    renderAmountTxt = (label, key, width) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                sx={{ width: width ? width : "16vw", marginTop: "0.9vw" }}
                size='small'
                autoComplete='off'
                label={t(label)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key] ? states[key] : ""}
                disabled = {this.props.is_From_Ip_AdmissionDetails}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        })
                    }
                }}
            />
        )
    }

    PatientBedSelection = () => {
        try {
            let states = this.state;
            return (
                <Grid container className='eMed_IPappoint_Grid_row'>
                    <Grid item xs={3}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Ward"}</InputLabel>
                            <Select label={"Select Ward"} sx={{ width: "95%" }} value={this.state.PatientWard} MenuProps={{ style: { maxHeight: 350 } }}
                                disabled = {this.props.is_From_Ip_AdmissionDetails}
                                onChange={(e) => {
                                    states.PatientBedtext = ""
                                    states.PatientBederror = false
                                    states.PatientFloorId = ''
                                    states.PatientFloorList = []
                                    states.PatientBedList = []
                                    states.PatientRoomList = []
                                    states.PatientBlock = ""
                                    states.PatientFloor = ""
                                    states.PatientRoom = ""
                                    states.PatientBed = ""
                                    this.setState({
                                        PatientWard: e.target.value,
                                        PatientBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === e.target.value)), "block_id"),
                                        states
                                    }, () => {
                                        if (this.state.PatientBlockList?.length == 1) {
                                            this.setState({
                                                PatientBlock: this.state.PatientBlockList[0].block_id,
                                                PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlockList[0].block_id)), "floor")
                                            }, () => {
                                                if (this.state.PatientFloorList?.length == 1) {
                                                    this.setState({
                                                        PatientFloor: this.state.PatientFloorList[0].floor,
                                                        PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloorList[0].floor)), "room_number")
                                                    }, () => {
                                                        if (this.state.PatientRoomList?.length == 1) {
                                                            this.setState({
                                                                PatientRoom: this.state.PatientRoomList[0].room_number,
                                                                PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                                                            }, () => {
                                                                if (this.state.PatientBedList?.length == 1) {
                                                                    if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.AttenderBed === this.state.PatientBedList[0].bed_id) {
                                                                        this.errorMessage("Same Bed is Selected for Attender")
                                                                    }
                                                                    else {
                                                                        this.setState({
                                                                            PatientBed: this.state.PatientBedList[0].bed_id,
                                                                            PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                                                        }, () => {
                                                                            this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }}>
                                {this.state.PatientWardList?.length > 0 ?
                                    this.state.PatientWardList.map((list, index) => (
                                        <MenuItem key={index} value={list?.ward_id}>{list?.ward_name}</MenuItem>
                                    )) :
                                    <MenuItem disabled value="">
                                        No Data
                                    </MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Block"}</InputLabel>
                            <Select label={"Select Block"} sx={{ width: "95%" }} disabled={this.state.PatientBlockList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.PatientBlock} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    states.PatientFloor = ""
                                    states.PatientBedtext = ""
                                    states.PatientBederror = false
                                    states.PatientBedList = []
                                    states.PatientRoomList = []
                                    states.PatientRoom = ""
                                    states.PatientBed = ""
                                    this.setState({
                                        PatientBlock: e.target.value,
                                        PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === e.target.value)), "floor"),
                                        states
                                    }, () => {
                                        if (this.state.PatientFloorList?.length == 1) {
                                            this.setState({
                                                PatientFloor: this.state.PatientFloorList[0].floor,
                                                PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloorList[0].floor)), "room_number")
                                            }, () => {
                                                if (this.state.PatientRoomList?.length == 1) {
                                                    this.setState({
                                                        PatientRoom: this.state.PatientRoomList[0].room_number,
                                                        PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                                                    }, () => {
                                                        if (this.state.PatientBedList?.length == 1) {
                                                            if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.AttenderBed === this.state.PatientBedList[0].bed_id) {
                                                                this.errorMessage("Same Bed is Selected for Attender")
                                                            }
                                                            else {
                                                                this.setState({
                                                                    PatientBed: this.state.PatientBedList[0].bed_id,
                                                                    PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                                                }, () => {
                                                                    this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }}>
                                {this.state.PatientBlockList?.length > 0 ?
                                    this.state.PatientBlockList.map((list, index) => (
                                        <MenuItem key={index} value={list?.block_id}>{list?.block_name}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Floor"}</InputLabel>
                            <Select label={"Select Floor"} sx={{ width: "95%" }} disabled={this.state.PatientFloorList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.PatientFloor} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    states.PatientBedList = []
                                    states.PatientBed = ""
                                    states.PatientRoom = ""
                                    this.setState({
                                        PatientFloor: e.target.value,
                                        PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == e.target.value)), "room_number"),
                                        PatientBedtext: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == e.target.value)), "room_number").length === 0 ? "No Rooms Available" : `${this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == e.target.value)), "room_number").length} Rooms Available`,
                                        PatientBederror: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == e.target.value)), "room_number").length === 0 ? true : false,
                                        states
                                    }, () => {
                                        if (this.state.PatientRoomList?.length == 1) {
                                            this.setState({
                                                PatientRoom: this.state.PatientRoomList[0].room_number,
                                                PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                                            }, () => {
                                                if (this.state.PatientBedList?.length == 1) {
                                                    if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.AttenderBed === this.state.PatientBedList[0].bed_id) {
                                                        this.errorMessage("Same Bed is Selected for Attender")
                                                    }
                                                    else {
                                                        this.setState({
                                                            PatientBed: this.state.PatientBedList[0].bed_id,
                                                            PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                                        }, () => {
                                                            this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                                        })
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }}>
                                {this.state.PatientFloorList?.length > 0 ?
                                    this.state.PatientFloorList.map((list, index) => (
                                        <MenuItem key={index} value={list.floor}>{list.floor}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Room"}</InputLabel>
                            <Select label={"Select room"} sx={{ width: "95%" }} disabled={this.state.PatientRoomList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.PatientRoom} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    this.setState({
                                        PatientRoom: e.target.value,
                                        PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === e.target.value)),
                                        PatientBedtext: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === e.target.value)).length === 0 ? "No Beds Available" : `${this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === e.target.value)).length} Beds Available`,
                                        PatientBederror: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === e.target.value)).length === 0 ? true : false
                                    }, () => {
                                        if (this.state.PatientBedList?.length == 1) {
                                            if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.AttenderBed === this.state.PatientBedList[0].bed_id) {
                                                this.errorMessage("Same Bed is Selected for Attender")
                                            }
                                            else {
                                                this.setState({
                                                    PatientBed: this.state.PatientBedList[0].bed_id,
                                                    PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                                }, () => {
                                                    this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                                })
                                            }
                                        }
                                    })
                                }}>
                                {this.state.PatientRoomList?.length > 0 ?
                                    this.state.PatientRoomList.map((list, index) => (
                                        <MenuItem key={index} value={list?.room_number}>{list?.room_number}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Bed"}</InputLabel>
                            <Select label={"Select Bed"} sx={{ width: "95%" }} disabled={this.state.PatientBedList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.PatientBed} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    if (states.PatientWard === states.AttenderWard && states.PatientBlock === states.AttenderBlock && states.PatientFloor === states.AttenderFloor && states.PatientRoom === states.AttenderRoom && states.AttenderBed === e.target.value) {
                                        this.errorMessage("Same Bed is Selected for Attender")
                                    } else {
                                        this.setState({ PatientBed: e.target.value, PatientBedtext: "", PatientBederror: false, PatientBedObj: this.state.WardList.find((item) => (e.target.value === item.bed_id)) }, () => {
                                            this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                        })
                                    }
                                }
                                }
                            >
                                {this.state.PatientBedList?.length > 0 ?
                                    this.state.PatientBedList.map((list, index) => (
                                        <MenuItem key={index} value={list?.bed_id}>{list?.bed_number}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    AttenderBedSelection = () => {
        try {
            let states = this.state;
            return (
                <Grid container className='eMed_IPappoint_Grid_row'>
                    <Grid item xs={3}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Ward"}</InputLabel>
                            <Select label={"Select Ward"} sx={{ width: "95%" }} disabled={this.state.AttenderWardList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.AttenderWard} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    states.AttenderBedtext = ""
                                    states.AttenderBederror = false
                                    states.AttenderFloorId = ''
                                    states.AttenderFloorList = []
                                    states.AttenderBedList = []
                                    states.AttenderRoomList = []
                                    states.AttenderBlock = ""
                                    states.AttenderFloor = ""
                                    states.AttenderRoom = ""
                                    states.AttenderBed = ""
                                    this.setState({
                                        AttenderWard: e.target.value,
                                        AttenderBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === e.target.value)), "block_id"),
                                        states
                                    }, () => {
                                        if (this.state.AttenderBlockList?.length == 1) {
                                            this.setState({
                                                AttenderBlock: this.state.AttenderBlockList[0].block_id,
                                                AttenderFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlockList[0].block_id)), "floor")
                                            }, () => {
                                                if (this.state.AttenderFloorList?.length == 1) {
                                                    this.setState({
                                                        AttenderFloor: this.state.AttenderFloorList[0].floor,
                                                        AttenderRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloorList[0].floor)), "room_number")
                                                    }, () => {
                                                        if (this.state.AttenderRoomList?.length == 1) {
                                                            this.setState({
                                                                AttenderRoom: this.state.AttenderRoomList[0].room_number,
                                                                AttenderBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === this.state.AttenderRoomList[0].room_number))
                                                            }, () => {
                                                                if (this.state.AttenderBedList?.length == 1) {
                                                                    if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.PatientBed === this.state.AttenderBedList[0].bed_id) {
                                                                        this.errorMessage("Same Bed is Selected for Attender")
                                                                    }
                                                                    else {
                                                                        this.setState({ AttenderBed: this.state.AttenderBedList[0].bed_id, })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }}>
                                {this.state.AttenderWardList?.length > 0 ?
                                    this.state.AttenderWardList.map((list, index) => (
                                        <MenuItem key={index} value={list?.ward_id}>{list?.ward_name}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Block"}</InputLabel>
                            <Select label={"Select Block"} sx={{ width: "95%" }} disabled={this.state.AttenderBlockList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.AttenderBlock} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    states.AttenderFloor = ""
                                    states.AttenderBedtext = ""
                                    states.AttenderBederror = false
                                    states.AttenderBedList = []
                                    states.AttenderRoomList = []
                                    states.AttenderRoom = ""
                                    states.AttenderBed = ""
                                    this.setState({
                                        AttenderBlock: e.target.value,
                                        AttenderFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === e.target.value)), "floor"),
                                        states
                                    }, () => {
                                        if (this.state.AttenderFloorList?.length == 1) {
                                            this.setState({
                                                AttenderFloor: this.state.AttenderFloorList[0].floor,
                                                AttenderRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloorList[0].floor)), "room_number")
                                            }, () => {
                                                if (this.state.AttenderRoomList?.length == 1) {
                                                    this.setState({
                                                        AttenderRoom: this.state.AttenderRoomList[0].room_number,
                                                        AttenderBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === this.state.AttenderRoomList[0].room_number))
                                                    }, () => {
                                                        if (this.state.AttenderBedList?.length == 1) {
                                                            if (this.state.AttenderBedList?.length == 1) {
                                                                if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.PatientBed === this.state.AttenderBedList[0].bed_id) {
                                                                    this.errorMessage("Same Bed is Selected for Patient")
                                                                }
                                                                else {
                                                                    this.setState({ AttenderBed: this.state.AttenderBedList[0].bed_id, })
                                                                }
                                                            }
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }}>
                                {this.state.AttenderBlockList?.length > 0 ?
                                    this.state.AttenderBlockList.map((list, index) => (
                                        <MenuItem key={index} value={list?.block_id}>{list?.block_name}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Floor"}</InputLabel>
                            <Select label={"Select Floor"} sx={{ width: "95%" }} disabled={this.state.AttenderFloorList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.AttenderFloor} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    states.AttenderBedList = []
                                    states.AttenderBed = ""
                                    states.AttenderRoom = ""
                                    this.setState({
                                        AttenderFloor: e.target.value,
                                        AttenderRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == e.target.value)), "room_number"),
                                        AttenderBedtext: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == e.target.value)), "room_number").length === 0 ? "No Rooms Available" : `${this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == e.target.value)), "room_number").length} Rooms Available`,
                                        AttenderBederror: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == e.target.value)), "room_number").length === 0 ? true : false,
                                        states
                                    }, () => {
                                        if (this.state.AttenderRoomList?.length == 1) {
                                            this.setState({
                                                AttenderRoom: this.state.AttenderRoomList[0].room_number,
                                                AttenderBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === this.state.AttenderRoomList[0].room_number))
                                            }, () => {
                                                if (this.state.AttenderBedList?.length == 1) {
                                                    if (this.state.AttenderBedList?.length == 1) {
                                                        if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.PatientBed === this.state.AttenderBedList[0].bed_id) {
                                                            this.errorMessage("Same Bed is Selected for Patient")
                                                        }
                                                        else {
                                                            this.setState({ AttenderBed: this.state.AttenderBedList[0].bed_id, })
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }}>
                                {this.state.AttenderFloorList?.length > 0 ?
                                    this.state.AttenderFloorList.map((list, index) => (
                                        <MenuItem key={index} value={list.floor}>{list.floor}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Room"}</InputLabel>
                            <Select label={"Select room"} sx={{ width: "95%" }} disabled={this.state.AttenderRoomList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.AttenderRoom} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    this.setState({
                                        AttenderRoom: e.target.value,
                                        AttenderBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === e.target.value)),
                                        AttenderBedtext: this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === e.target.value)).length === 0 ? "No Beds Available" : `${this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === e.target.value)).length} Beds Available`,
                                        AttenderBederror: this.state.WardList.filter((item) => (item.ward_id === this.state.AttenderWard && item.block_id === this.state.AttenderBlock && item.floor == this.state.AttenderFloor && item.room_number === e.target.value)).length === 0 ? true : false
                                    }, () => {
                                        if (this.state.AttenderBedList?.length == 1) {
                                            if (this.state.AttenderBedList?.length == 1) {
                                                if (this.state.PatientWard === this.state.AttenderWard && this.state.PatientBlock === this.state.AttenderBlock && this.state.PatientFloor === this.state.AttenderFloor && this.state.PatientRoom === this.state.AttenderRoom && this.state.PatientBed === this.state.AttenderBedList[0].bed_id) {
                                                    this.errorMessage("Same Bed is Selected for Patient")
                                                }
                                                else {
                                                    this.setState({ AttenderBed: this.state.AttenderBedList[0].bed_id, })
                                                }
                                            }
                                        }
                                    })
                                }}>
                                {this.state.AttenderRoomList?.length > 0 ?
                                    this.state.AttenderRoomList.map((list, index) => (
                                        <MenuItem key={index} value={list?.room_number}>{list?.room_number}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size='small' sx={{ width: "95%", marginTop: '0.8vw' }}>
                            <InputLabel>{"Select Bed"}</InputLabel>
                            <Select label={"Select Bed"} sx={{ width: "95%" }} disabled={this.state.AttenderBedList.length === 0 || this.props.is_From_Ip_AdmissionDetails} value={this.state.AttenderBed} MenuProps={{ style: { maxHeight: 350 } }}
                                onChange={(e) => {
                                    if (states.PatientWard === states.AttenderWard && states.PatientBlock === states.AttenderBlock && states.PatientFloor === states.AttenderFloor && states.PatientRoom === states.AttenderRoom && states.PatientBed === e.target.value) {
                                        this.errorMessage("Same Bed is Selected for Patient")
                                    }
                                    else {
                                        this.setState({ AttenderBed: e.target.value, AttenderBedtext: "", AttenderBederror: false })
                                    }
                                }}>
                                {this.state.AttenderBedList?.length > 0 ?
                                    this.state.AttenderBedList.map((list, index) => (
                                        <MenuItem key={index} value={list?.bed_id}>{list?.bed_number}</MenuItem>
                                    )) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    BedDetailsLabel(Type) {
        try {
            const { t } = this.props
            return (
                <Grid container className='eMed_IPappoint_Grid_row'>
                    <Grid item xs={2.3}>
                        {this.renderTextBox(`${t("Ward")}`, Type === "patient" ? this.state.PatientWard : this.state.AttenderWard, "VisitConsult1", 50, "90%", false, true)}
                    </Grid>
                    <Grid item xs={2.3}>
                        {this.renderTextBox(`${t("Block")}`, Type === "patient" ? this.state.PatientBlock : this.state.AttenderBlock, "VisitConsult2", 50, "90%", false, true)}
                    </Grid>
                    <Grid item xs={2.3}>
                        {this.renderTextBox(`${t("Floor")}`, Type === "patient" ? this.state.PatientFloor : this.state.AttenderFloor, "VisitConsult3", 50, "90%", false, true)}
                    </Grid>
                    <Grid item xs={2.3}>
                        {this.renderTextBox(`${t("Room")}`, Type === "patient" ? this.state.PatientRoom : this.state.AttenderRoom, "VisitConsult4", 50, "90%", false, true)}
                    </Grid>
                    <Grid item xs={2.3}>
                        {this.renderTextBox(`${t("Bed")}`, Type === "patient" ? this.state.PatientBed : this.state.AttenderBed, "VisitConsult4", 50, "90%", false, true)}
                    </Grid>
                </Grid>
            )
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    renderComponentLeft() {
        const { t } = this.props;
        let isDisable = (Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.isPatientDischarged || this.props.is_From_Ip_AdmissionDetails);
        let isMedicoDisable = (!this.state.IsMedicoLegal || this.props.isPatientDischarged || Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.is_From_Ip_AdmissionDetails);
        let isDisabledByPolicy = (Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.isPatientDischarged || this.state.policyStatus || this.props.is_From_Ip_AdmissionDetails);
        return (
            <Paper className='eMed_IPappoint_left' sx={{ backgroundColor: Colors.ComponentBgColor, paddingX: '0.3vw' }}>
                <Typography className='eMed_IPappoint_title'>{t("AdmissionDetails")}</Typography>
                <Paper component={'div'} sx={{ backgroundColor: 'white' }}>
                    <Box component={'div'} paddingY={"1vw"}>
                        <Grid container className='eMed_IPappoint_Grid_row'>
                            <Grid item xs={3}>
                                {this.renderDateTimePicker(`${t("AdmissionDateTime*")}`, this.state.AddmissionDateTime, "AddmissionDateTime", "97%", (this.props.isfrom_Patientdetails || Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.is_From_Ip_AdmissionDetails) ? true : false)}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("AdmittingConsultant*")}`, "Doctor", this.state.DocList, "doctor_name", "doctor_id", isDisable, "97%")}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("Co Consultant")}`, "CoConsultant", this.state.DocList, "doctor_name", "doctor_id", isDisable, "97%")}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("Nurse")}`, "Nurse", this.state.NurseList, "nurse", "staff_id", isDisable, "97%")}
                            </Grid>
                        </Grid>
                        <Grid container className='eMed_IPappoint_Grid_row'>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("VisitConsult1")}`, "VisitConsult1", this.state.DocList, "doctor_name", "doctor_id", isDisable, "97%")}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("VisitConsult2")}`, "VisitConsult2", this.state.DocList, "doctor_name", "doctor_id", isDisable, "97%")}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("VisitConsult3")}`, "VisitConsult3", this.state.DocList, "doctor_name", "doctor_id", isDisable, "97%")}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("VisitConsult4")}`, "VisitConsult4", this.state.DocList, "doctor_name", "doctor_id", isDisable, "97%")}
                            </Grid>
                        </Grid>
                        <Grid container className='eMed_IPappoint_Grid_row'>
                            <Grid item xs={6}>
                                {this.renderTextBox(`${t("SurgeonName")}`, this.state.SurgeonName, "SurgeonName", 60, "28.5vw", false, isDisable)}
                            </Grid>
                            <Grid item xs={6}>
                                {this.ComplaintsTextBox()}
                                {/* {this.renderTextBox(`${t("Complaints*")}`, this.state.Complaints, "Complaints", 200, "28.5vw", false, false)} */}
                            </Grid>
                        </Grid>
                        <Grid container className='eMed_IPappoint_Grid_row'>
                            <Grid item xs={3}>
                                {this.renderDropdown(`${t("Tag")}`, "Tag", this.state.TagList, "patient_tag_name", "id", isDisable, "97%")}
                            </Grid>
                            <Grid item xs={4}>
                                {this.renderAmountTxt(`${t("IPCreditLmt")}`, "CreditLmt", "16vw")}
                            </Grid>
                            <Grid item xs={4}>
                                {this.renderAmountTxt(`${t("PharmaCreditLmt")}`, "PharmaCreditLmt", "16vw")}
                            </Grid>
                        </Grid>

                    </Box>
                    <Divider variant="middle" />
                    <Box component={'div'} paddingY={"1vw"}>
                        <Grid item xs={12} sx={{ marginTop: "0.5vw" }}>
                            <FormControl sx={{ marginLeft: "1.5vw", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <label>{t("MedicoLegalCase")} -</label>
                                <RadioGroup
                                    value={this.state.IsMedicoLegal}
                                    sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                                    onChange={(e) => {
                                        this.setState({ IsMedicoLegal: e.target.value === "true" ? true : false }, () => {
                                            if (!this.state.IsMedicoLegal) {
                                                this.setState({
                                                    MedicoDate: null,
                                                    MedicoInformer: "",
                                                    MedicoLocation: "",
                                                    MedicoPoliceStation: "",
                                                    MedicoComments: "",
                                                })
                                            }
                                        })
                                    }}
                                >
                                    <FormControlLabel value={true} control={<Radio disabled={isDisable} size='small' />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio disabled={isDisable} size='small' />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Box component={'div'} className='eMed_IPappoint_Medico_div'>
                            <Box width={"55%"}>
                                <Grid container className='eMed_IPappoint_Grid_row'>
                                    <Grid item xs={6}>
                                        {this.renderDateTimePicker(`${t("IncidentDateTime*")}`, this.state.MedicoDate, "MedicoDate", "97%", isMedicoDisable)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {this.renderTextBox(`${t("InformerName*")}`, this.state.MedicoInformer, "MedicoInformer", 50, "97%", false, isMedicoDisable)}
                                    </Grid>
                                </Grid>
                                <Grid container className='eMed_IPappoint_Grid_row' marginTop={"1vw"}>
                                    <Grid item xs={6}>
                                        {this.renderTextBox(`${t("IncidentLocation*")}`, this.state.MedicoLocation, "MedicoLocation", 100, "97%", false, isMedicoDisable)}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {this.renderTextBox(`${t("PoliceStation")}`, this.state.MedicoPoliceStation, "MedicoPoliceStation", 100, "97%", false, isMedicoDisable)}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box width={"45%"} marginLeft={'0.5vw'}>
                                {this.renderTextBox(`${t("Comments")}`, this.state.MedicoComments, "MedicoComments", 200, "26vw", true, isMedicoDisable)}
                            </Box>
                        </Box>
                    </Box>
                    <Divider variant="middle" />

                    <Box component={'div'} paddingY={"1vw"} >
                        <Typography marginLeft={'1.5vw'}>{t("AttenderDetails")}</Typography>
                        <Grid container className='eMed_IPappoint_Grid_row'>
                            <Grid item xs={3}>
                                {this.renderTextBox(`${t("AttenderName")}`, this.state.AttenderName, "AttenderName", 100, "97%", false, isDisable)}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderTextBox(`${t("MobileNo")}`, this.state.AttenderMobileNo, "AttenderMobileNo", 10, "97%", false, isDisable)}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderTextBox(`${t("Relationship")}`, this.state.AttenderRelation, "AttenderRelation", 50, "97%", false, isDisable)}
                            </Grid>
                            <Grid item xs={3}>
                                {this.renderTextBox(`${t("Location")}`, this.state.AttenderLocation, "AttenderLocation", 50, "97%", false, isDisable)}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Paper sx={{ marginTop: '1vw' }}>
                    <Box component={'div'} borderBottom={'1px solid lightgray'} pl={'1vw'} height={'5vh'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                       <Box component={'div'} display={'flex'}>
                       <Typography fontSize={'0.9vw'} fontWeight={600}>Payment Type</Typography>
                       <Tooltip placement='top' title='Clear' arrow>
                       <Button className='Common_Btn_Min_Width' sx={{height:'1.5vw',width:'1.5vw',ml:'0.5vw'}}
                       disabled={isDisable}
                        onClick={() => this.setState({
                            selectedCorporateNumber:'',
                            selectedInsuranceNumber:'',
                            selectedCorporate:null,
                            selectedInsurance:null
                        })}
                        >
                            <Box component={'img'} src={ImagePaths.NewClearIcon.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                       </Tooltip>
                       </Box>
                        <Box component={'div'}>
                            <RadioGroup
                                value={this.state.selectedPaymentType}
                                sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                                onChange={(e) => { 
                                    this.setState({ selectedPaymentType: e.target.value }) 
                                }}
                            >
                                <FormControlLabel value={"Corporate credit"} disabled={isDisabledByPolicy} control={<Radio size='small' />} label="Corporate" />
                                <FormControlLabel value={"Insurance credit"} disabled={isDisabledByPolicy} control={<Radio size='small' />} label="Insurance" />
                                <FormControlLabel value={"Patient credit"} disabled={isDisabledByPolicy} control={<Radio size='small' />} label="Patient Credit" />
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box component={'div'} mt={'0.5vw'}>
                        <Box component={'div'} pl={'1vw'} height={'5vh'} display={'flex'} alignItems={'center'} borderBottom={'1px solid lightgray'}>
                            <Typography fontSize={'0.9vw'} fontWeight={'600'}>{this.state.selectedPaymentType === 'Corporate credit' ? 'Corporate Details' :
                                this.state.selectedPaymentType === 'Insurance credit' ? 'Insurance Details' : 'Patient Credit'}</Typography>
                        </Box>
                        <Box component={'div'} p={'1vw'}>
                            {this.state.selectedPaymentType === 'Corporate credit' ?
                                <Box component={'div'} display={'flex'}>
                                    <Autocomplete
                                        disabled={isDisabledByPolicy}
                                        size='small'
                                        sx={{ width: '15vw', mr: '1vw' }}
                                        options={this.state.CorporateList}
                                        getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.company_name}
                                        value={this.state.selectedCorporate}
                                        onChange={(e, value) => {
                                            this.setState({ selectedCorporate: value })
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Select Corporate *' />}
                                    />
                                    <TextField
                                        disabled={isDisabledByPolicy}
                                        size='small'
                                        sx={{ width: '15vw' }}
                                        label='Employee ID No *'
                                        value={this.state.selectedCorporateNumber}
                                        onChange={(e) => this.setState({ selectedCorporateNumber: e.target.value })}
                                    />
                                </Box>
                                : this.state.selectedPaymentType === 'Insurance credit' ?
                                    <Box component={'div'} display={'flex'}>
                                        <Autocomplete
                                            disabled={isDisabledByPolicy}
                                            size='small'
                                            sx={{ width: '15vw', mr: '1vw' }}
                                            options={this.state.InsuranceList}
                                            getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.insurance_name}
                                            value={this.state.selectedInsurance}
                                            onChange={(e, value) => {
                                                this.setState({ selectedInsurance: value })
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Select Insurance *' />}
                                        />
                                        <TextField
                                            disabled={isDisabledByPolicy}
                                            size='small'
                                            sx={{ width: '15vw' }}
                                            label='Insurance No *'
                                            value={this.state.selectedInsuranceNumber}
                                            onChange={(e) => this.setState({ selectedInsuranceNumber: e.target.value })}
                                        />
                                    </Box> : 
                                    <Typography fontSize={'0.9vw'} color={Colors.SecondaryText}>No Insurance Or Corporate Details</Typography>
                            }
                        </Box>
                    </Box>
                </Paper>
                {/* {this.props.isfrom_Patientdetails ? null : */}
                    <Paper sx={{ marginTop: '1vw' }}>
                    <Box className='eMed_IPappoint_title' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box component={'div'} display={'flex'}>
                            <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} fontWeight={600}>{t("PatientWardAllotment")}{this.state.PatientWardList.length === 0 ? this.WardSelectionMessage() : this.BedSelectionMessage()}</Typography>
                            <Tooltip placement='top' title='Clear' arrow>
                                <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw', width: '1.5vw',ml:'0.5vw' }}
                                    disabled={Object.keys(this.props?.bacKfromBilling).length !== 0 || this.props?.isPatientDischarged || this.props?.isfrom_Patientdetails || this.props.is_From_Ip_AdmissionDetails}
                                    onClick={() => this.setState({
                                        PatientWard:'',
                                        PatientBlock:'',
                                        PatientFloor:'',
                                        PatientRoom:'',
                                        PatientBed:'',
                                        PatientBlockList:[],
                                        PatientRoomList:[],
                                        PatientFloorList:[],
                                        PatientBedList:[],
                                        PatientBedtext:''
                                    })}
                                >
                                    <Box component={'img'} src={ImagePaths.NewClearIcon.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                            </Tooltip>
                        </Box>
                            <Box sx={{ marginRight: "1.5vw", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormControl >
                                    {
                                        (Object.keys(this.state.PatientBedObj).length > 0 && this.state.PatientBed !== "") ?
                                            <RadioGroup
                                                value={this.state.PatientRoomType}
                                                sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                                                onChange={(e) => { this.setState({ PatientRoomType: e.target.value }) }}
                                            >
                                                <FormControlLabel value={"hourly"} control={<Radio disabled={!this.state.PatientBedObj?.is_hourly || this.props.is_From_Ip_AdmissionDetails} size='small' />} label="Hourly" />
                                                <FormControlLabel value={"day"} control={<Radio disabled={!this.state.PatientBedObj?.is_day || this.props.is_From_Ip_AdmissionDetails} size='small' />} label="Day" />
                                            </RadioGroup>
                                            : null
                                    }
                                </FormControl>
                                <Tooltip title="Patient Bed Availability List" placement='top' arrow>
                                    <IconButton disabled={Object.keys(this.props?.bacKfromBilling).length !== 0 || this.props?.isPatientDischarged || this.props?.isfrom_Patientdetails || this.props.is_From_Ip_AdmissionDetails} onClick={() => { this.setState({ ReservationListPopup: "patient" }) }}><ErrorOutlineIcon color={(Object.keys(this.props?.bacKfromBilling).length !== 0 || this.props?.isPatientDischarged || this.props?.isfrom_Patientdetails) ? "grey" : 'primary'} /></IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ backgroundColor: 'white' }} paddingBottom={"1.5vw"} paddingTop={"0.7vw"}>
                            {
                                (Object.keys(this.props?.bacKfromBilling)?.length === 0 && !this.state.patient_Data.patinet_bed_id) ? this.PatientBedSelection() : this.BedDetailsLabel("patient")
                            }
                        </Box>
                    </Paper>
                    {/* } */}

                {/* {this.props.isfrom_Patientdetails ? null : */}
                    <Paper sx={{ marginTop: '1vw' }}>
                        <Box className='eMed_IPappoint_title' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Box component={'div'} display={'flex'}>
                            <Typography sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} fontWeight={600}>{t("AttenderRoom")}{this.AttenderBedSelectionMessage()}</Typography>
                            <Tooltip placement='top' title='Clear' arrow>
                                <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw', width: '1.5vw',ml:'0.5vw' }}
                                    disabled={(Object.keys(this.props?.bacKfromBilling)?.length !== 0 || this.state.attrnder_Data.attender_bed_id || this.props.is_From_Ip_AdmissionDetails)}
                                    onClick={() => this.setState({
                                        AttenderWard:'',
                                        AttenderBlock:'',
                                        AttenderFloor:'',
                                        AttenderRoom:'',
                                        AttenderBed:'',
                                        AttenderBlockList:[],
                                        AttenderFloorList:[],
                                        AttenderRoomList:[],
                                        AttenderBedList:[],
                                        AttenderBedtext:''
                                    })}
                                >
                                    <Box component={'img'} src={ImagePaths.NewClearIcon.default} height={'1.5vw'} width={'1.5vw'} />
                                </Button>
                            </Tooltip>
                        </Box>
                            <Box sx={{ marginRight: "1.5vw", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormControl >
                                    <RadioGroup
                                        value={"day"}
                                        sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                                    >
                                        <FormControlLabel value={"day"} control={<Radio disabled size='small' />} label="Day" />
                                    </RadioGroup>
                                </FormControl>
                                <Tooltip title="Attender Bed Availability List" placement='top' arrow>
                                    <IconButton  disabled={(Object.keys(this.props?.bacKfromBilling)?.length !== 0 || this.state.attrnder_Data.attender_bed_id || this.props.is_From_Ip_AdmissionDetails)} onClick={() => { this.setState({ ReservationListPopup: "attender" }) }}><ErrorOutlineIcon color={(Object.keys(this.props?.bacKfromBilling)?.length !== 0 || this.state.attrnder_Data.attender_bed_id) ? "grey" : 'primary'} /></IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ backgroundColor: 'white' }} paddingBottom={"1.5vw"} paddingTop={"0.7vw"}>
                            {
                                (Object.keys(this.props?.bacKfromBilling)?.length === 0 && !this.state.attrnder_Data.attender_bed_id) ? this.AttenderBedSelection() : this.BedDetailsLabel("attender")
                            }
                        </Box>
                    </Paper>
                    {/* } */}

                {/* <Paper sx={{ marginTop: '1vw' }}>
                    <Accordion elevation={2}>
                        <AccordionSummary
                            className='eMed_IPappoint_title'
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography sx={{ fontWeight: 600 }}>{t("RestraintConsent")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='eMed_TextEditor_Accord'>
                            <Divider />
                            <MUIRichTextEditor
                                controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList", "quote", "clear", "save"]}
                                label={` Type ${t("RestraintConsent")} Here...`}
                                onSave={(data) => { this.setState({ RestraintConsentText: data }) }}
                                inlineToolbar={true}
                                defaultValue={this.state.RestraintConsentText}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Paper> */}
                {this.props.isfrom_Patientdetails || this.props.is_From_Ip_AdmissionDetails ? null :
                    <Paper sx={{ marginTop: '1vw', marginBottom: '2vw' }}>
                        <Accordion elevation={2}>
                            <AccordionSummary
                                className='eMed_IPappoint_title'
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography sx={{ fontWeight: 600 }}>{t("ConsentForAdmission")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='eMed_TextEditor_Accord'>
                                <Divider />
                                <MUIRichTextEditor
                                    controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "numberList", "bulletList", "quote", "clear", "save"]}
                                    label={` Type ${t("ConsentForAdmission")} Here...`}
                                    onSave={(data) => { this.setState({ ConsentForAdmissionText: data }) }}
                                    inlineToolbar={true}
                                    defaultValue={this.state.ConsentForAdmissionText}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Paper>}
            </Paper>
        )
    }
    FindByID(List, Id, IdKey, ValueKey) {
        let Data = ""
        Data = List.length > 0 ? List.filter((item) => (item[IdKey] === Id)) : []
        Data = Data.length > 0 ? Data[0][ValueKey] : ""
        return Data
    }
    PostIpAdmission() {
        try {
            var states = this.state
            let IpPatientDetails = getCachevalue('IppatientData')
            let patientData = JSON.parse(IpPatientDetails)
            let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
            var data = {
                "patient_id": states.PatientDetails?.patient_id,
                "doctor_id": states.Doctor,
                "co_consultant_id": states.CoConsultant ? states.CoConsultant : null,
                "admission_date": DateTime.fromJSDate(this.state.AddmissionDateTime).toFormat("yyyy-MM-d"),
                "admission_time": DateTime.fromJSDate(this.state.AddmissionDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                "nurse_id": states.Nurse ? states.Nurse : null,
                "complaints": CommonValidation.removeSpace(states.Complaints),
                "floor": states.PatientFloor,
                "room": states.PatientRoom,
                "bed_id": this.props.isfrom_Patientdetails ? states.bed_ID : states.PatientBed,
                "attender_bed_id": this.props.isfrom_Patientdetails ? (states.attender_bed_ID !== '' ? states.attender_bed_ID : states.AttenderBed) : states.AttenderBed === "" ? "" :  states.AttenderBed,
                "patient_account_number": states.PatientDetails?.patient_account_number,
                "tentative_discharge_date": null,
                "visiting_consultant": typeof (states.VisitConsult1) !== 'number' ? CommonValidation.removeSpace(states.VisitConsult1) : states?.DocList.find((item)=> item?.doctor_id === states.VisitConsult1)?.doctor_name ,
                "visiting_consultant_2": typeof (states.VisitConsult2) !== 'number' ? CommonValidation.removeSpace(states.VisitConsult2) : states?.DocList.find((item)=> item?.doctor_id === states.VisitConsult2)?.doctor_name,
                "visiting_consultant_3": typeof (states.VisitConsult3) !== 'number' ? CommonValidation.removeSpace(states.VisitConsult3) : states?.DocList.find((item)=> item?.doctor_id === states.VisitConsult3)?.doctor_name,
                "visiting_consultant_4": typeof (states.VisitConsult4) !== 'number' ? CommonValidation.removeSpace(states.VisitConsult4) : states?.DocList.find((item)=> item?.doctor_id === states.VisitConsult4)?.doctor_name,
                "visiting_doctor_id": typeof (states.VisitConsult1) !== 'number' ? null : states.VisitConsult1,
                "visiting_doctor_2_id": typeof (states.VisitConsult2) !== 'number' ? null : states.VisitConsult2,
                "visiting_doctor_3_id": typeof (states.VisitConsult3) !== 'number' ? null : states.VisitConsult3,
                "visiting_doctor_4_id": typeof (states.VisitConsult4) !== 'number' ? null : states.VisitConsult4,
                "surgeon_name": CommonValidation.removeSpace(states.SurgeonName),
                "medico_legal_case": states.IsMedicoLegal,
                "incident_date": states.MedicoDate === null ? null : DateTime.fromJSDate(states.MedicoDate).toFormat("yyyy-MM-d"),
                "incident_time": states.MedicoDate === null ? null : DateTime.fromJSDate(states.MedicoDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS),
                "incident_location": CommonValidation.removeSpace(states.MedicoLocation),
                "informer_name": CommonValidation.removeSpace(states.MedicoInformer),
                "police_station": CommonValidation.removeSpace(states.MedicoPoliceStation),
                "medico_legal_comments": CommonValidation.removeSpace(states.MedicoComments),
                "ward": this.FindByID(states.PatientWardList, states.PatientWard, "ward_id", "ward_name"),
                "block": this.FindByID(states.PatientBlockList, states.PatientBlock, "block_id", "block_name"),
                "attender_name": CommonValidation.removeSpace(states.AttenderName),
                "attender_mobile": CommonValidation.removeSpace(states.AttenderMobileNo),
                "attender_relationship": CommonValidation.removeSpace(states.AttenderRelation),
                "attender_location": CommonValidation.removeSpace(states.AttenderLocation),
                "attender_ward_name": states.AttenderBed === "" ? "" : this.FindByID(states.AttenderWardList, states.AttenderWard, "ward_id", "ward_name"),
                "attender_floor_number": states.AttenderBed === "" ? "" : states.AttenderFloor,
                "attender_block_name": states.AttenderBed === "" ? "" : this.FindByID(states.AttenderBlockList, states.AttenderBlock, "block_id", "block_name"),
                "attender_room_number": states.AttenderBed === "" ? "" : states.AttenderRoom,
                "patient_tags_id": states.Tag,
                "restaint_Consent": states.RestraintConsentText,
                "consent_for_admission": states.ConsentForAdmissionText,
                'is_hourly': states.PatientRoomType === "hourly",
                'is_day': states.PatientRoomType === "day",
                "attender_is_hourly": false,
                "attender_is_day": true,
                "ip_admission_credit_limit": states.CreditLmt ? +CommonValidation.removeSpace(states.CreditLmt) : 0,
                "ip_pharmacy_credit_limit": states.PharmaCreditLmt ? +CommonValidation.removeSpace(states.PharmaCreditLmt) : 0,
                "clinic_id": UserData?.clinic_id,
                "ot_id": states.PatientDetails?.ot_id ? states.PatientDetails.ot_id : states.PatientDetails?.OTid ? states.PatientDetails.OTid : null,
                "credit_type":this.state.selectedPaymentType === 'Corporate credit' ? 'Corprate credit': this.state.selectedPaymentType === 'Insurance credit' ? "Insurance credit" : "Patient credit",
                "employer_id":this.state.selectedPaymentType === 'Corporate credit' ? (this.state.selectedCorporate?.id ? this.state.selectedCorporate?.id : this.state.selectedCorporateId) : null,
                "id_no":this.state.selectedPaymentType === 'Corporate credit' ? this.state.selectedCorporateNumber : "",
                "insurance_company_name":this.state.selectedPaymentType === 'Insurance credit' ? (this.state.selectedInsurance?.insurance_name ? this.state.selectedInsurance?.insurance_name : this.state.selectedInsurance) : '',
                "policy_no":this.state.selectedPaymentType === 'Insurance credit' ? this.state.selectedInsuranceNumber : ''
                
            }

            if (this.props.isfrom_Patientdetails) {
                data["ip_admission_id"] = patientData?.id
                let patientTag = this.state.TagList?.find(item => item?.id === states.Tag)
                patientData.patient_tags_id = patientTag?.id
                patientData.attender_name = this.state.AttenderName ? this.state.AttenderName : ""
                patientData.attender_mobile = this.state.AttenderMobileNo ? this.state.AttenderMobileNo : ""
                patientData.patient_tags__patient_tag_name = patientTag?.patient_tag_name
                if (data?.credit_type !== patientData?.primary_payment_type) {
                    patientData.primary_payment_type = data.credit_type
                }
            }
        if(this.state.selectedPaymentType === 'Corporate credit' && ((this.state.selectedCorporate === null || this.state.selectedCorporate === '') || (this.state.selectedCorporateNumber === '' || this.state.selectedCorporateNumber === null))){
            this.errorMessage("Select Corporate Details")
        }else if(this.state.selectedPaymentType === 'Insurance credit' && ((this.state.selectedInsurance === null || this.state.selectedInsurance === '') || (this.state.selectedInsuranceNumber === '' || this.state.selectedInsuranceNumber === null))){
            this.errorMessage("Select Insurance Details")
        }else{
            this.lodaerFunction(true)
            RestAPIService.create(data, Serviceurls.IP_ADMISSION_DETAILS).
                then((response) => {
                    if (response?.data?.status === "success") {
                        this.lodaerFunction(false)
                        this.setState({ DisableBtn : false})
                        setCachevalue(JSON.stringify(patientData),'IppatientData')
                        if (this.props.isfrom_Patientdetails) {
                            this.props.history.push("/IPHome/PatientDetails/PatientDetails")
                        } else if (this.state.PatientDetails.fromOTModule) {
                            this.props.history.push({ pathname: "/OTHome", state: { success: response?.data?.message, id: this.state.PatientDetails.OTid, fromIP : true} })
                        } else if (this.state.PatientDetails.fromOTHome) {
                            this.props.history.push({ pathname: "/IPHome/OThome", state: { success: response?.data?.message, id: this.state.PatientDetails.OTid, fromIP : true} })
                        } else {
                            this.props.IPAdmisssionSuccess(response?.data?.message, response?.data?.data)
                        }
                        this.setState({selectedCorporateId:null})
                    } else {
                        this.lodaerFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.lodaerFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.lodaerFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    Validation() {
        let states = this.state;
        if (
            !(DateTime.fromJSDate(this.state.AddmissionDateTime).toFormat("yyyy-MM-d") === "Invalid DateTime") &&
            !(DateTime.fromJSDate(this.state.AddmissionDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS) == "Invalid DateTime") &&
            states.Doctor !== "" &&
            // states.Nurse !== "" &&
            CommonValidation.removeSpace(states.Complaints) !== "" &&
            states.PatientBed !== "" &&
            ((states.AttenderBed === null || states.AttenderBed === '') || (CommonValidation.removeSpace(states.AttenderName) !== ""))
        ) {
            if (states.IsMedicoLegal) {
                if (
                    (!(DateTime.fromJSDate(this.state.MedicoDate).toFormat("yyyy-MM-d") === "Invalid DateTime")) &&
                    !(DateTime.fromJSDate(this.state.MedicoDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) == "Invalid DateTime") &&
                    CommonValidation.removeSpace(states.MedicoInformer) !== "" &&
                    CommonValidation.removeSpace(states.MedicoLocation) !== ""
                ) {
                    this.PostIpAdmission()
                }
                else {
                    this.setState({ DisableBtn: false }, () => {
                        if ((DateTime.fromJSDate(this.state.MedicoDate).toFormat("yyyy-MM-d") === "Invalid DateTime") || (DateTime.fromJSDate(this.state.MedicoDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) == "Invalid DateTime")) {
                            this.errorMessage("Invalid Incident Date & Time")
                        }
                        else if (CommonValidation.removeSpace(states.MedicoInformer) === "") {
                            this.errorMessage("Enter the Informer Name")
                        }
                        else if (CommonValidation.removeSpace(states.MedicoLocation) === "") {
                            this.errorMessage("Enter the Location of the Incident")
                        }
                    })
                }
            }
            else {
                this.PostIpAdmission()
            }
        }
        else {
            this.setState({ DisableBtn : false }, ()=>{
                if ((DateTime.fromJSDate(this.state.AddmissionDateTime).toFormat("yyyy-MM-d") === "Invalid DateTime") || (DateTime.fromJSDate(this.state.AddmissionDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS) == "Invalid DateTime")) {
                    this.errorMessage("Invalid Admission Date & Time")
                }
                else if (states.Doctor === "") {
                    this.errorMessage("Select the Admitting Consultant")
                }
                // mandatory for nurse removal
                // else if (states.Nurse === "") {
                //     this.errorMessage("Select the Nurse")
                // }
                else if (CommonValidation.removeSpace(states.Complaints) === "") {
                    this.errorMessage("Enter the Reason / Diagnosis")
                }
                else if (states.PatientBed === "") {
                    this.errorMessage("Select the Bed for Patient")
                }
                else if (((states.AttenderBed !== null || states.AttenderBed !== '') && CommonValidation.removeSpace(states.AttenderName) === "")) {
                    this.errorMessage("Enter the Attender Name")
                }
            })
        }
    }
    clearData() {
        this.setState({
            IsMedicoLegal: false,
            PatientBlockList: [],
            PatientFloorList: [],
            PatientBedList: [],
            PatientRoomList: [],
            PatientWard: "",
            PatientBlock: "",
            PatientFloor: "",
            PatientRoom: "",
            PatientBed: "",
            PatientFloorId: "",
            PatientBederror: false,
            PatientBedtext: "",

            AttenderBlockList: [],
            AttenderFloorList: [],
            AttenderBedList: [],
            AttenderRoomList: [],
            AttenderWard: "",
            AttenderBlock: "",
            AttenderFloor: "",
            AttenderRoom: "",
            AttenderBed: "",
            AttenderFloorId: "",
            AttenderBederror: false,
            AttenderBedtext: "",

            AddmissionDateTime: new Date(),
            Doctor: "",
            CoConsultant: "",
            Nurse: "",
            Tag: "",
            VisitConsult1: "",
            VisitConsult2: "",
            VisitConsult3: "",
            VisitConsult4: "",
            SurgeonName: "",
            Complaints: "",
            MedicoDate: null,
            MedicoInformer: "",
            MedicoLocation: "",
            MedicoPoliceStation: "",
            MedicoComments: "",
            AttenderName: "",
            AttenderMobileNo: "",
            AttenderRelation: "",
            AttenderLocation: "",
            PatientBedObj: {},
            PatientRoomType: "hourly",
            CreditLmt: 0,
            PharmaCreditLmt: 0,

            selectedCorporate:null,
            selectedInsurance:null,
            selectedCorporateNumber:'',
            selectedInsuranceNumber:'',
            selectedPaymentType:'Patient credit'
        })
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            BackBtnClicked: false
        })
    }
    AllowBack() { { this.props.BackToRegistration(this.state.PatientDetails) } }
    reserveClose() { { this.setState({ ReservationListPopup: null }) } }
    RommDetail(item) {
        var states = this.state
        if(this.state.ReservationListPopup === "patient"){
            states.PatientWardList.push({
                ward_id: item.ward_id,
                ward_name: item.ward__ward_name
            })
            states.PatientBlockList.push({
                block_id: item.block_no_id,
                block_name: item.block
            })
            states.PatientFloorList.push({
                floor: item.floor
            })
            states.PatientRoomList.push({
                room_number: item.room_number
            })
            states.PatientBedList.push({
                bed_id: item.bed_id,
                bed_number: item.bed_number
            })
            this.setState({
                PatientWard: item.ward_id,
                PatientBlock: item.block_no_id,
                PatientFloor: item.floor,
                PatientRoom: item.room_number,
                PatientBed: item.bed_id,
                states,
                PatientBedObj: item
            }, () => {
                this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
            })
        }else{
            states.AttenderWardList.push({
                ward_id: item.ward_id,
                ward_name: item.ward__ward_name
            })
            states.AttenderBlockList.push({
                block_id: item.block_no_id,
                block_name: item.block
            })
            states.AttenderFloorList.push({
                floor: item.floor
            })
            states.AttenderRoomList.push({
                room_number: item.room_number
            })
            states.AttenderBedList.push({
                bed_id: item.bed_id,
                bed_number: item.bed_number
            })
            this.setState({
                AttenderWard: item.ward_id,
                AttenderBlock: item.block_no_id,
                AttenderFloor: item.floor,
                AttenderRoom: item.room_number,
                AttenderBed: item.bed_id,
                states,
            })
        }
        
        
    }
    render() {
        try {
            return (
                <Box component={"div"} className='eMed_Ipappoint_container'>
                    <Grid container spacing={1} className='eMed_IPappoint_content' >
                        <Grid item xs={8} sx={{ padding: 0 }}>
                            {this.renderComponentLeft()}
                        </Grid>
                        <Grid item xs={4}>
                            {this.renderComponentRight()}
                        </Grid>
                        {this.props.is_From_Ip_AdmissionDetails ? null : 
                        <Grid item xs={12}>
                            <Box className='eMed_Ipappoint_btn_div'>
                                <Button id="eMed_invd_Button" size='small' variant='outlined' onClick={() => { this.state.PatientDetails.fromOTModule ? this.props.history.push("/OTHome") : this.state.PatientDetails.fromOTHome ? this.props.history.push("/IPHome/OThome") : this.props.isfrom_Patientdetails ? this.props.history.push("/IPHome/PatientDetails/PatientDetails") : Object.keys(this.props.bacKfromBilling).length === 0 ? this.setState({ BackBtnClicked: true }) : this.props.BackToRegistration(this.state.PatientDetails) }}>Back</Button>
                                <Button disabled={Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.isPatientDischarged} id="eMed_invd_Button" size='small' variant='outlined' onClick={() => { this.clearData() }}>Clear</Button>
                                <Button disabled={this.state.isLoader ? true : Object.keys(this.props.bacKfromBilling).length !== 0 || this.props.isPatientDischarged} id="eMed_invd_Button" emed_tid="Create_Admission_Btn" size='small' variant='contained' onClick={() => { this.Validation() }}>{this.props.isfrom_Patientdetails ? 'Update Admission' : 'Create Admission'}</Button>
                                {
                                    Object.keys(this.props.bacKfromBilling).length !== 0 ?
                                        <Button id="eMed_invd_Button" size='small' variant='outlined' onClick={() => { this.props.BackToBilling() }}>Next</Button> : null
                                }
                            </Box>
                        </Grid>}
                    </Grid>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    <Loader loaderOpen={this.state.isLoader} />
                    {this.state.BackBtnClicked ? <DeletePopup
                        DeletTitle={` Are you sure to discard the entered Values ?`}
                        deleteAlertPopupClose={this.msgClose.bind(this)}
                        removeData={this.AllowBack.bind(this)}
                        DeleteNotify={(" Cannot Retrieve the Values")}
                        ButtonText={'Yes'}
                    /> : null}
                    {(this.state.ReservationListPopup === "patient" || this.state.ReservationListPopup === "attender") ? 
                    <ReservationList 
                    ListType={this.state.ReservationListPopup} 
                    sendRommDetail={this.RommDetail.bind(this)}
                    AdmissionDate={DateTime.fromJSDate(this.state.AddmissionDateTime).toFormat("yyyy-MM-d")} 
                    PopClose={this.reserveClose.bind(this)} /> : null}
                </Box>
            )
        }
        catch (e) {
            this.errorMessage(e.message)
            return null
        }
    }
}
export default withTranslation()(IPAddmissionDetails)

