import React, { Component } from 'react';
import {
    Badge,
    Box, Button, IconButton, MenuItem, Paper, Stack, Tooltip, Menu, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import BillingsFilter from './BillingsFilter';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import CreditBillPopUp from './CreditBillPopUp';
import { Colors } from '../../../Styles/Colors';
import { CheckAccessFunc, ContentInTooltip, DifferntTimeConverter, formatDate, OpenDirectPrint, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import CommonGridHeader, { CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { width } from '@mui/system';
import EditHistoryPopup from './EditHistoryPopup';
import { CurrencySymbol } from '../../../Utility/Constants';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';

let userAccess = null;


class OverAllBills extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
        let LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)).map((list) => list?.laboratory_id) : []
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            ref: props.location?.pathname,
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "PayStatus": [],
                "PayMethods": [],
                'BillStatus': ["regular", "credit", "return"],
                "CompletedSelected": true,
                "CreditSelected": true,
                "ReturnSelected": true,
                "CancelledSelected": false,
                "startTime": null,
                "endTime": null,
                'billType': ['Credit', 'Bill', 'Return'],
                'credit': true,
                'completed': true,
                'return': true,
                "BillModuleType": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? ['op', 'ip', 'lab', 'radiology', 'ot'] : localGetItem("Show_Radiology_Bills_In_FO") === "false" ? ['op', 'ip', 'pharmacy', 'lab', 'ot'] : ['op', 'ip', 'pharmacy', 'lab', 'radiology', 'ot'],
                "opCreditBills": true,
                "ipCreditBills": true,
                "pharmaCreditBills": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "labCreditBills": true,
                "RadiologyBills": localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected": localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills": localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "otCreditBills": true,
                "billFrom": "",
                "billTo": "",
                'returnFrom': '',
                'returnTo': '',
                'cancelFrom': '',
                'cancelTo': '',
                'UserList': [],
                'UserDetails': [],
                'MultiPharmaIds': localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? [] : PharmacyList,
                'MultiLabIds': LabList,
                'paymentStatus':['paid','not paid','partial'],
                'selectedPaid':true,
                'selectedNotPaid':true,
                'selectedPartial':true,
                'tokenNumber':false
            },
            AmountDetails: {
                "TotalBills": 0,
                "TotalAmount": 0,
            },
            BillPreviewPop: false,
            SelectedBill: {},
            AppointmentDetails: {},
            CreditBillPopUp: false,
            SelectedBill: {},
            isPharmacy: false,
            BillModifyHistoryItem: {},
            per_Access: {},
            ConfigSetting: {},
            showTokenPrint:false
        }
    }

    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        userAccess = CheckAccessFunc(UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy", "Billing Transaction", 'Overall Bills', null, "Tab");
        this.setState({
            isPharmacy: UserData?.module_name == "Pharmacy" ? true : false,
            per_Access: UserData?.permission_access,
            showTokenPrint: UserData?.show_pharmacy_sales_token ? true : false
        }, () => {
            this.GetBillList()
            this.GetUserDetails()
            if (this.state.isPharmacy) {
                this.GetPharmaSettings()
            }

        })
    }

    GetPharmaSettings() {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_CONFIG_SETTINGS).
                then((response) => {
                    if (response.data.status === "succes") {
                        this.setState({ ConfigSetting: response.data?.data })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetUserDetails = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DAY_END_USER_DETAIL_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        states.FilterDatas["UserList"] =  response.data.data
                        this.setState({
                            states
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }

                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetBillList() {
        try {
            var states = this.state
            let User_ID = []
            states.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
            let pharmaSetStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let pharmaSetEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let doctorId = [];
            states.FilterDatas.DoctorName.forEach(element => doctorId.push(element.doctor_id))
            this.LoaderFunction(true)
            RestAPIService.getAllTwo(states.isPharmacy ? Serviceurls.PHARMACY_OVERALL_BILL_LIST + "?from_date=" + states.FilterDatas.FromDate + "&to_date=" + states.FilterDatas.ToDate +
                "&doctor_id=" + doctorId + "&mobile_no=" + states.FilterDatas.MobNo + "&bill_no=" + states.FilterDatas.BillNumber + "&account_number=" + states.FilterDatas.AccNo +
                "&patient_name=" + states.FilterDatas.PatientName + "&start_time=" + pharmaSetStartTime + "&end_time=" + pharmaSetEndTime + '&bill_type=' + states.FilterDatas.billType +
                "&bill_no_from=" + states.FilterDatas.billFrom + "&bill_no_to=" + states.FilterDatas.billTo + '&sales_bill_only=' + states.FilterDatas.tokenNumber
                // + '&payment_status=' + states?.FilterDatas?.paymentStatus
                // + "&return_no_from=" + states?.FilterDatas?.returnFrom + "&return_no_to=" + states?.FilterDatas?.returnTo 
                : Serviceurls.OVERALL_BILLS_GET +
                "?patient_name=" + states.FilterDatas.PatientName +
                "&account_number=" + states.FilterDatas.AccNo +
                "&mobile_number=" + states.FilterDatas.MobNo +
                "&doctor_id=" + doctorId +
                "&bill_number=" + states.FilterDatas.BillNumber +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&start_time=" + setStartTime +
                "&end_time=" + setEndTime +
                "&bills=" + states?.FilterDatas?.BillModuleType +
                "&bill_type=" + states?.FilterDatas?.BillStatus +
                "&bill_no_from=" + states?.FilterDatas?.billFrom +
                "&bill_no_to=" + states?.FilterDatas?.billTo + 
                "&user_id=" + User_ID +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds 
                // + '&payment_status=' + states?.FilterDatas?.paymentStatus
                // "&return_no_from=" + states?.FilterDatas?.returnFrom + 
                // "&return_no_to=" + states?.FilterDatas?.returnTo + 
                // "&cancel_no_from=" + states?.FilterDatas?.cancelFrom + 
                // "&cancel_no_to=" + states?.FilterDatas?.cancelTo
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        states.AmountDetails.TotalBills = response?.data?.no_of_bill ? response?.data?.no_of_bill : response?.data?.total_bill;
                        states.AmountDetails.TotalAmount = response?.data?.total_amount ? response?.data?.total_amount : response?.data?.over_all_amount;
                        this.setState({
                            BillList: response.data.data,
                            AmountDetails: states.AmountDetails,
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
            CreditBillPopUp: false
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.GetBillList()
            }
        })
    }
    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetBillList() })
    }

    getSavedPrint = (data, option) => {
        try {
            let url = ''
            if (option === "TokenPrint") {
                url = Serviceurls.PHARMA_BILL_TOKEN_PRINT
            }
            RestAPIService.getAllPrint(url + `?bill_summary_id=${data?.id ? data?.id : null}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isSelected : false, selectedRow: null})
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e?.response?.data?.message)
                    } else {
                        this.errorMessage(e?.message)
                    }
                    this.setState({ isSelected : false, selectedRow: null})
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ isSelected : false, selectedRow: null})
        }
    }

    MenuItem = (data) => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        const { t } = this.props
        let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
        let ModifyBillAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.modify_credit_bill ? RoleData?.permission_access?.common?.modify_credit_bill : false) : true
        let cancelBillApproval = RoleData?.is_user ? (RoleData?.permission_access?.common?.cancel_bill_approval ? RoleData?.permission_access?.common?.cancel_bill_approval : false) : true
        let rtnPeriodValue = this.state.ConfigSetting?.return_bill_period
        let rtnPerionType = this.state.ConfigSetting?.return_period_type
        // let nowdate = new Date()
        // let time = new Date(nowdate.getFullYear(), nowdate.getMonth() - isSettings, nowdate.getDate())
        // let exam = DateTime.fromJSDate(time).toFormat('yyyy-MM-dd')
        // let configdate = new Date(exam)
        let billdate = data?.row?.invoice_date;
        const originalDate = DateTime.fromFormat(billdate, 'dd-MM-yyyy hh:mm a');
        const formattedDate = originalDate.toFormat('yyyy-MM-dd');
        let isReturn = true
        // if ((new Date(formattedDate)) >= (new Date(configdate))) {
        //     isReturn = UserData?.is_user ? (this.state.per_Access.pharma.isReturnBill ? false : true) : false ;
        // }
        if (rtnPerionType === 'Days') {
            let beforeDate = CommonValidation.getDateBefore(rtnPeriodValue)
            let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
            if (new Date(formattedDate) >= new Date(configDate)) {
                isReturn = UserData?.is_user ? (this.state.per_Access.pharma.isReturnBill ? false : true) : false;
            }
        } else if (rtnPerionType === 'Weeks') {
            let beforeDate = CommonValidation.getDateBeforeWeeks(rtnPeriodValue)
            let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
            if (new Date(formattedDate) >= new Date(configDate)) {
                isReturn = UserData?.is_user ? (this.state.per_Access.pharma.isReturnBill ? false : true) : false;
            }
        } else if (rtnPerionType === 'Months') {
            let beforeDate = CommonValidation.getDateBeforeMonths(rtnPeriodValue)
            let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
            if (new Date(formattedDate) >= new Date(configDate)) {
                isReturn = UserData?.is_user ? (this.state.per_Access.pharma.isReturnBill ? false : true) : false;
            }
        }
        const multi_option = [
            { value: "CancelBill", label: t("CancelBill") },
            { value: "ModifyBill", label: t("Modify Bill") },// will be sliced when no access
            { value: "ModifyHistory", label: t("Modify History") }
        ]
        if (!ModifyBillAccess) {
            multi_option.splice(1, 1);
        }
        if (!cancelBillApproval) {
            multi_option.splice(0, 1);
        }
        let pharmacy_option = []
        if (data?.row?.bill_type === "Return") {
            pharmacy_option.push({ value: "ViewBill", label: t("View Bill") })
        } else if (rtnPerionType === 'No Return') {
            pharmacy_option.push({ value: "ViewBill", label: t("View Bill") })
        } else {
            pharmacy_option.push({ value: "ReturnBill", label: t("Return Bill") })
            pharmacy_option.push({ value: "ViewBill", label: t("View Bill") })
        }
        if(this.state.showTokenPrint && data?.row?.temp_invoice_sequence){
            pharmacy_option?.push({ value: "TokenPrint", label: t("Token Print") },)
        } 
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        sx={{ width: '2vw' }}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true,
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {this.state.isPharmacy ?
                            pharmacy_option.map((option) => {

                                let testId_value = option?.value === "ModifyBill" ? (data?.row?.bill_type === "Saved" || data?.row?.bill_type === "Credit") ? "Complete Bill" : t(option?.label) :
                                    option?.value === "ReturnBill" ? (data?.row?.bill_type === "Return") ? "View Bill" : t(option?.label) : t(option?.label)

                                return (
                                    <MenuItem emed_tid={`overall_${option.value}`} key={option}
                                        disabled={
                                            (option.value === "ModifyBill" && data?.row?.bill_type === "Return") ||
                                                (option.value === "ReturnBill" && ((data?.row?.bill_type === "Saved"))) ||
                                                (data?.row?.billing_status === "Deleted") ||
                                                (option.value === "ReturnBill" && ((data?.row?.bill_type !== "Return" && isReturn))) ? true : false} onClick={() => {
                                                    if (option.value === "ModifyBill") {
                                                        if (data?.row?.bill_type === "Credit") {
                                                            this.props.history.push({ pathname: "/PharmacyInvoice/CreditBills/Bills", state: { SelectedCreditBill: data?.row } })
                                                        } else if (data?.row?.bill_type === "Saved") {
                                                            this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: data?.row?.patient_id, Bill_ID: data?.row?.id, isFromSavedBill: true, billDetails: data?.row } })
                                                        } else if (data?.row?.bill_type === "Bill") {
                                                            this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: data?.row?.patient_id, Bill_ID: data.row?.id, isForInvoiceEdit: true, billDetails: data.row } })
                                                        }
                                                    } else if (option.value === "ReturnBill") {
                                                        this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: data?.row?.patient_id, Bill_ID: data?.row?.id, isFromInvoice: true, billDetails: data?.row } })
                                                    } else if (option.value === "ViewBill") {
                                                        if (data?.row?.master_return) {
                                                            this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: data?.row?.patient_id, Bill_ID: data?.row?.id, billDetails: data?.row, isMasterReturn: true } })
                                                        } else {
                                                            this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: data?.row?.patient_id, Bill_ID: data?.row?.id, isFromInvoice: true, billDetails: data?.row, isForView: true } })
                                                        }
                                                    }else if(option?.value === 'TokenPrint'){
                                                        this.getSavedPrint(data?.row,option?.value)
                                                    }
                                                }}>
                                        {option?.value === "ReturnBill" ? (data?.row?.bill_type === "Return") ? "View Bill" : t(option?.label) : t(option.label)}
                                    </MenuItem>
                                )
                            })
                            :
                            multi_option.map((option) => (
                                <MenuItem key={option}
                                    disabled={(option?.value === 'CancelBill' && data?.row?.bill_types === 'Laboratory' && (data?.row?.result_status === 'Result Delivered' || data?.row?.result_status === 'Result Completed') && (localGetItem('lab_cancel_bill') === 'false') ? true : false) ||
                                        (option?.value === "ModifyBill" ? (((data.row.bill_types === "Out Patient" || data.row.bill_types === "In Patient" || data.row.bill_types === "Radiology" || (data.row.bill_types === "Laboratory" && data?.row?.result_status === "Result Inprogress")) && data.row.bill_type === "credit" && data?.row?.payment_status === "not paid") ? false : true) : false)
                                    }
                                    onClick={() => {
                                        this.MenuClick(data, option?.value)
                                    }}
                                >
                                    {t(option.label)}
                                </MenuItem>
                            ))
                        }
                    </Menu> : null}
            </div>
        )
    }

    MenuClick = (data, selectedOption) => {
        let { history } = this.props
        try {
            if (selectedOption === "CancelBill" || selectedOption === "ModifyBill") {
                let url = ""
                let id = ""
                let ApiCall = false
                if (data.row.bill_types === "Out Patient") {
                    url = Serviceurls.FO_GET_APPOINMENT_DETAILS
                    id = "?appointment_id=" + data.row.appointment_id
                    ApiCall = true
                } else if (data.row.bill_types === "In Patient") {
                    url = Serviceurls.FO_GET_IP_ADMISSION_DETAILS
                    id = "?ip_admission_id=" + data.row.ip_number_id
                    ApiCall = true
                } else if (data.row.bill_types === "Laboratory") {
                    setCachevalue(true, "isForCancelBill")
                    setCachevalue(JSON.stringify({ ForCancel: true, cancelBillData: data.row }), "LabCancelBilldata")
                    history.push({ pathname: "/Billing/LabBilling", state: { ForCancel: true, cancelBillData: data.row, isForEditBill: selectedOption === "ModifyBill" } })
                } else if (data.row.bill_types === "Radiology") {
                    setCachevalue(true, "isForCancelBill")
                    setCachevalue(JSON.stringify({ fromModifyBill: selectedOption === "ModifyBill", billSummaryId: data?.row?.id, ForCancel: true, AmountNet: data?.row?.amount_net, isForEditBill: selectedOption === "ModifyBill" }), "RadCancelBillData")
                    this.props.history?.push({ pathname: '/Billing/RadiologyBilling', state: { fromModifyBill: true, billSummaryId: data?.row?.id, ModifyFromFO: selectedOption === "ModifyBill", CancelFromFO: selectedOption === "CancelBill", patientData: data.row } })
                } 
                if (ApiCall) {
                    RestAPIService.getAll(url + id).
                        then((response) => {
                            if (response.data.status === "success") {
                                this.setState({
                                    AppointmentDetails: response.data.data,
                                    anchorEl: null
                                }, () => {
                                    let path = data.row.bill_types === "Out Patient" ? "/OPHome/PatientDetails/Billing" : data.row.bill_types === "In Patient" ? "/IPHome/PatientDetails/ServiceBilling" : false
                                    let ID = data.row.bill_types === "Out Patient" ? data.row.appointment_id : data.row.bill_types === "In Patient" ? data.row.ip_number_id : null
                                    let Amt = data.row.amount_net
                                    if (path) {
                                        if (data.row.bill_types === "Out Patient") {
                                            setCachevalue(JSON.stringify({ AppointmentID: ID, InvoiceID: data.row.id, ForCancel: true, AmountNet: Amt, isForEditBill: selectedOption === "ModifyBill" }), "OPCancelBilldata")
                                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "patientData")
                                            setCachevalue(true, "isForCancelBill")
                                        } else if (data.row.bill_types === "In Patient") {
                                            setCachevalue(JSON.stringify({ AppointmentID: ID, InvoiceID: data.row.id, ForCancel: true, AmountNet: Amt, isForEditBill: selectedOption === "ModifyBill" }), "IPCancelBilldata")
                                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "IppatientData")
                                            setCachevalue(true, "isForCancelBill")
                                        }
                                        history.push({ pathname: path, state: { AppointmentID: ID, InvoiceID: data.row.id, ForCancel: true, AmountNet: Amt, isForEditBill: selectedOption === "ModifyBill" } })
                                    }
                                })
                            }
                            else {
                                this.errorMessage(response.data.message)
                            }
                        }).catch((error) => {
                            this.errorMessage(error.message)
                        })
                }
            } else if (selectedOption === "ModifyHistory") {
                this.setState({ BillModifyHistoryItem: data.row })
            }
        }
        catch (e) {
            this.errorMessage(e.message)
        }

    }
    GetAppointmentDetails(id, billid) {
        try {
            const { history } = this.props;
            RestAPIService.getAll(Serviceurls.FO_GET_APPOINMENT_DETAILS + "?appointment_id=" + id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data
                        }, () => {
                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "patientData")
                            history.push({ pathname: "/OPHome/PatientDetails/Billing", state: { AppointmentID: id, InvoiceID: billid } })
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }
    GetIPAppointmentDetails(id, billid) {
        try {
            const { history } = this.props;
            RestAPIService.getAll(Serviceurls.FO_GET_IP_ADMISSION_DETAILS + "?ip_admission_id=" + id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data
                        }, () => {
                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "IppatientData")
                            history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling", state: { AppointmentID: id, InvoiceID: billid } })
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    CrebillPosted(message) {
        this.setState({
            CreditBillPopUp: false,

        }, () => {
            this.successMessage(message)
            this.GetBillList()
        })
    }

    PaymentStatus(status, Data) {
        const { t } = this.props
        switch (status) {
            case "not paid": return <Tooltip placement='top' title={`Unpaid`} arrow>
                <div className='eMed_OP_APtTbl_PayPending'>
                    <img className='eMed_OP_AptTbl_PayIcon' src={ImagePaths.Warning.default}></img>
                    <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: "#B8170D" }}>{CurrencySymbol} {Data?.pending_amount}</Typography>
                </div></Tooltip>
            case "partial": return <Tooltip placement='top' title={`Payment Pending`} arrow>
                <div className='eMed_OP_APtTbl_PayPending'>
                    <img className='eMed_OP_AptTbl_PayIcon' src={ImagePaths.PayPending.default}></img>
                    <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: Colors.orange }}>{CurrencySymbol} {Data?.pending_amount}</Typography>
                </div></Tooltip>
            case "paid": return <Tooltip placement='top' title={t("PaymentCompleted")} arrow>
                <div className='eMed_OP_APtTbl_PayPending'>
                    <img className='eMed_OP_AptTbl_PayCompletedIcon' src={ImagePaths.PayCompleted.default}></img>
                    <Typography id='eMed_OP_AptTbl_PayText' sx={{ color: Colors.themeDark }}>{CurrencySymbol} {Data?.amount_net}</Typography>
                </div></Tooltip>
            default: return <Typography marginLeft={'2vw'} id='eMed_OP_AptTbl_PayText'>{"-"}</Typography>

        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
        ]

        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        var ExportData = [];
        if (this.state.isPharmacy) {
            this.state.BillList?.length > 0 && this.state.BillList.map((item) => {
                ExportData.push({
                    "Date And Time": item?.invoice_date,
                    "Bill Number": item?.invoice_numbers,
                    "Bill Type": item.bill_type,
                    "Payment Status": item.payment_status,
                    "Patient Name": item.patient_name,
                    "Patient UHID": item.patient_account_number,
                    "Doctor Name": item.doctor_name,
                    "Bill Amount": item.net_amount,
                    "Received Amount": item.received_amount,
                    "Paid In": item.paid_in
                });
            });
        } else {
            this.state.BillList?.length > 0 && this.state.BillList.map((item) => {
                ExportData.push({
                    "Date And Time": item.invoice_date,
                    "Bill Number": item.bill_number,
                    "Module Type": item.bill_types,
                    "Bill Type": item.bill_type,
                    "Payment Status": item.payment_status,
                    "Patient Name": item.patient_name,
                    "Patient UHID": item.patient_uhid,
                    "Doctor/Nurse Name": item.doctor_name,
                    "Bill Amount": item.amount_net,
                    "Received Amount": item.received_amount
                })
            });

        }

        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom data={ExportData} title={this.state.isPharmacy ? "Pharmacy OverallBills" : "FO Overall Bills"} FilterTextArray={TextArray} filename={"OverallBills"} />
            </Box>
        );
    }

    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    printReports = (data) => {
        try {
            var states = this.state
            this.setState({ disableBtn: true })
            RestAPIService.PrintPDF(Serviceurls.PHARMACY_INVOICE_PRINT + "?id=" + data?.id + "&export=pdf" + "&pharmacy_id=" + data?.pharmacy_id + "")
                .then((response) => {
                    // const file = new Blob(
                    //     [response.data],
                    //     { type: 'application/pdf' });
                    // const fileURL = URL.createObjectURL(file);
                    // window.open(fileURL);
                    OpenDirectPrint(response)
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    PrintAllBills = () => {
        try {
            let APIURL = ""
            let states = this.state
            let User_ID = []
            states.FilterDatas.UserDetails.forEach(element => User_ID.push(element.id))
            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let pharmaSetStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let pharmaSetEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS) + ".000";
            let doctorId = [];
            states.FilterDatas.DoctorName.forEach(element => doctorId.push(element.doctor_id))
            if (this.state.isPharmacy) {
                APIURL = Serviceurls.PHARMACY_OVERALL_BILL_LIST +
                    "?from_date=" + states.FilterDatas.FromDate +
                    "&to_date=" + states.FilterDatas.ToDate +
                    "&doctor_id=" + doctorId +
                    "&mobile_no=" + states.FilterDatas.MobNo +
                    "&bill_no=" + states.FilterDatas.BillNumber +
                    "&account_number=" + states.FilterDatas.AccNo +
                    "&patient_name=" + states.FilterDatas.PatientName +
                    "&start_time=" + pharmaSetStartTime + "&end_time=" + pharmaSetEndTime + '&bill_type=' + states.FilterDatas.billType + "&bill_no_from=" + states.FilterDatas.billFrom + "&bill_no_to=" + states.FilterDatas.billTo +
                    '&sales_bill_only=' + states.FilterDatas.tokenNumber + "&export=pdf"  
                    // + '&payment_status=' + states?.FilterDatas?.paymentStatus
            } else {
                APIURL = Serviceurls.OVERALL_BILLS_GET +
                    "?patient_name=" + states.FilterDatas.PatientName +
                    "&account_number=" + states.FilterDatas.AccNo +
                    "&mobile_number=" + states.FilterDatas.MobNo +
                    "&doctor_id=" + doctorId +
                    "&bill_number=" + states.FilterDatas.BillNumber +
                    "&paid_amount=" + states.FilterDatas.PaidAmount +
                    "&from_date=" + states.FilterDatas.FromDate +
                    "&to_date=" + states.FilterDatas.ToDate +
                    "&moduel_type=" + states.FilterDatas.BillType +
                    "&start_time=" + setStartTime +
                    "&end_time=" + setEndTime +
                    "&bills=" + states?.FilterDatas?.BillModuleType +
                    "&bill_no_from=" + states?.FilterDatas?.billFrom +
                    "&bill_no_to=" + states?.FilterDatas?.billTo +
                    "&bill_type=" + states?.FilterDatas?.BillStatus +
                    "&user_id=" + User_ID + "&export=pdf" +
                    "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                    "&laboratory_id=" + states?.FilterDatas?.MultiLabIds 
                    // + '&payment_status=' + states?.FilterDatas?.paymentStatus
            }
            this.setState({ disableBtn: true })
            RestAPIService.PrintPDF(APIURL)
                .then((response) => {
                    // const file = new Blob(
                    //     [response.data],
                    //     { type: 'application/pdf' });
                    // const fileURL = URL.createObjectURL(file);
                    // window.open(fileURL);
                    OpenDirectPrint(response)
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    HistoryPopClose() { this.setState({ BillModifyHistoryItem: {} }) }

    render() {
        this.state.BillList.forEach((element, index) => { element.sno = index + 1 })
        const { t } = this.props
        const { history } = this.props;
        const columns = [
            {
                field: "invoice_date", flex: 0.11, headerName: t("DateAndTime"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "bill_number", flex: 0.09, headerName: t("BillNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let BillNumber = "";
                    if (params?.row?.temp_bill_number && params?.row?.bill_number) { BillNumber = params?.row?.bill_number }
                    else if (params?.row?.bill_number) { BillNumber = params?.row?.bill_number }
                    else if (params?.row?.temp_bill_number) { BillNumber = params?.row?.temp_bill_number }
                    const billArr = BillNumber ? BillNumber?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "bill_types", flex: 0.07, headerName: t("Module Type"),
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.bill_types ? params?.row?.bill_types : "-"}</Typography>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.7vw"} >{
                            (params?.row?.bill_types === "Pharmacy" && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(params?.row?.pharmacy_name ? params?.row?.pharmacy_name : "-", 10, "0.8vw") :
                                (params?.row?.bill_types === "Laboratory" && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(params?.row?.laboratory_name ? params?.row?.laboratory_name : "-", 10, "0.8vw") : ""}</Typography>
                    </Box>)
            },
            {
                field: "bill_type", flex: 0.07, headerName: t("BillType"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.bill_type ? params?.row?.bill_type : "-"}</Typography>
                    <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8vw"} >{(params?.row?.bill_type === "Bill" || params?.row?.bill_type === "Regular") ? "Paid" : (params?.row?.payment_status ? params?.row?.payment_status : "-")}</Typography>
                </Box>)
            },
            {
                field: "paid_in", flex: 0.07, headerName: t("Paid In"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let data = params?.row?.paid_in ? params?.row?.paid_in.split(",") : []
                    let isFo = data.includes("FRONT OFFICE")
                    let ispharma = data.includes("PHARMACY")
                    let isLab = data.includes("LABORATORY")
                    let isRadiology = data.includes("RADIOLOGY")                    
                    if (isFo || ispharma || isLab || isRadiology) {
                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <Typography display={isFo ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Front Office</Typography>
                                <Typography display={ispharma ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Pharmacy</Typography>
                                <Typography display={isLab ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Lab</Typography>
                                <Typography display={isRadiology ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Radiology</Typography>
                            </Box>
                        )
                    } else {
                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>-</Typography>
                            </Box>
                        )
                    }
                }
            },
            {
                field: "patient_name", flex: 0.13, headerName: t("PatientName"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_name ? params?.row?.patient_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.patient_name} arrow>
                            <div>{params?.row?.patient_name.slice(0, 18) + "..."}</div></Tooltip> :
                        params?.row?.patient_name : "-"}</Box>)
            },
            {
                field: "patient_uhid", flex: 0.11, headerName: t("UHID"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_uhid ? params?.row?.patient_uhid?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.patient_uhid} arrow>
                            <div>{params?.row?.patient_uhid.slice(0, 15) + "..."}</div></Tooltip> :
                        params?.row?.patient_uhid : "-"}</Box>)
            },
            {
                field: "doctor_name", flex: 0.12, headerName: t("DoctorNurseName"),
                renderCell: (params) => {
                    let doctorNurseName = (params?.row?.doctor_name) ? (params?.row?.doctor_name) : (params?.row?.nurse_name);
                    <Box component={'div'}>
                        {doctorNurseName?.length > 18 ?
                            <Tooltip placement='top' title={doctorNurseName} arrow>
                                <div>{doctorNurseName.slice(0, 18) + "..."}</div></Tooltip> :
                            doctorNurseName ? doctorNurseName : "-"}</Box>
                }
            },
            {
                field: "amount_net", flex: 0.06, headerName: t('Bill'), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.amount_net ? AmountFormat(params?.row?.amount_net)?.replace("₹", "") : params?.row?.amount_net === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "received_amount", flex: 0.06, headerName: "Received", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.received_amount ? AmountFormat(params?.row?.received_amount)?.replace("₹", "") : params?.row?.received_amount === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "action", flex: 0.07, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                        </Button></Tooltip>
                    {(params?.row?.bill_types === "Out Patient" || params?.row?.bill_types === "In Patient" || params?.row?.bill_types === "Laboratory" || params?.row?.bill_types === "Radiology") && (params?.row?.bill_type !== "cancelled") ?
                        this.MenuItem(params) :
                        <Box sx={{ width: '2vw' }}></Box>}
                </Box>
            }
        ]
        const PharmacyColumns = [
            {
                field: "invoice_date", flex: 0.143, headerName: t("DateAndTime"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "invoice_numbers", flex: 0.143, headerName: t("BillNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let BillNumber = params?.row?.invoice_numbers ? (params?.row?.invoice_numbers) : (params?.row?.temp_invoice_number ? params?.row?.temp_invoice_number : "");
                    const billArr = BillNumber ? BillNumber?.split("/") : [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "patient_name", flex: 0.163, headerName: t("PatientName"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_name ? params?.row?.patient_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.patient_name} arrow>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                <div style={{ fontWeight: 600, fontSize: "0.9vw" }}>{params?.row?.patient_name.slice(0, 20) + "..."}</div>
                                <div style={{ fontSize: "0.9vw" }}>{`${(params?.row?.patient_account_number ? params?.row?.patient_account_number : "-")} | ${(params?.row?.customer_mobile_no ? params?.row?.customer_mobile_no : "-")}`}</div>
                            </div>
                        </Tooltip> :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                            <div style={{ fontWeight: 600, fontSize: "0.9vw" }}>{params?.row?.patient_name}</div>
                            <div style={{ fontSize: "0.9vw" }}>{`${(params?.row?.patient_account_number ? params?.row?.patient_account_number : "-")} | ${(params?.row?.customer_mobile_no ? params?.row?.customer_mobile_no : "-")}`}</div>
                        </div> : "-"}</Box>)
            },
            {
                field: "doctor_name", flex: 0.13, headerName: t("DoctorName"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.doctor_name ? params?.row?.doctor_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.doctor_name} arrow>
                            <div>{params?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip> :
                        params?.row?.doctor_name : "-"}</Box>)
            },
            {
                field: "net_amount", flex: 0.133, headerName: t(`Bill Amount ${CurrencySymbol}`), type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.net_amount ? AmountFormat(params?.row?.net_amount)?.replace(`${CurrencySymbol}`, "") : params?.row?.net_amount === 0 ? "0" : "-"}</Box>)
            },
            {
                field: "bill_type", flex: 0.1, headerName: t("Bill Type"), headerAlign: "center", align: "center",
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.bill_type ? params?.row?.bill_type : "-"}</Typography>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.8vw"} >{(params?.row?.bill_type === "Bill" || params?.row?.bill_type === "Regular") ? "Paid" : (params?.row?.payment_status ? params?.row?.payment_status : "-")}</Typography>
                    </Box>)
            },
            {
                field: "paid_in", flex: 0.07, headerName: t("Paid In"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let data = params?.row?.paid_in ? params?.row?.paid_in.split(",") : []
                    let isFo = data.includes("FRONT OFFICE")
                    let ispharma = data.includes("PHARMACY")
                    let isLab = data.includes("LABORATORY")
                    if (isFo || ispharma || isLab) {
                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <Typography display={isFo ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Front Office</Typography>
                                <Typography display={ispharma ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Pharmacy</Typography>
                                <Typography display={isLab ? "inline" : "none"} textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>Lab</Typography>
                            </Box>
                        )
                    } else {
                        return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.75vw"}>-</Typography>
                            </Box>
                        )
                    }
                }
            },
            {
                field: "action", flex: 0.143, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button emed_tid={'ovr_prt_icon'} disabled={this.state.disableBtn} onClick={() => { this.printReports(params.row) }} className='eMed_usrconf_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="print" className='eMed_action_img' />
                        </Button>
                    </Tooltip>
                    {userAccess?.editAccess ? this.MenuItem(params) : null}
                </Box>
            }
        ]
        let TokenNumber = {
            field: "temp_invoice_sequence", flex: 0.10, headerName: t("Token Number"), headerAlign: "center", align: "center",
            renderCell: (params) => (
                <Box component={'div'}>
                    {params?.row?.temp_invoice_sequence ? params?.row?.temp_invoice_sequence?.length > 10 ?
                        <Tooltip placement='top' title={params?.row?.temp_invoice_sequence} arrow>
                            <div>{"..." + params?.row?.temp_invoice_sequence?.slice(-10)}</div></Tooltip> :
                        params?.row?.temp_invoice_sequence : "-"}</Box>)
        }

        if (this.state.showTokenPrint) {
            PharmacyColumns?.splice(2, 0, TokenNumber)
        }
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("NoofBills"), this.state.AmountDetails?.TotalBills, true, "white", false, null, 'no_bill')}
                        {AmountsCard(t("TotalBillAmount"), this.state.AmountDetails?.TotalAmount, false, "white", false, null, 'total_bill')}
                        {/* {AmountsCard(t("BillPaidAmount"), this.state.AmountDetails.Paid)} */}
                        {/* {AmountsCard(t("PendingAmount"), this.state.AmountDetails.Pending)} */}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        <Tooltip title="Print" placement='top' arrow>
                            <Button className='eMed_usrconf_btn' emed_tid='OverallBill_Print_Btn' disabled={this.state.disableBtn} onClick={() => { this.PrintAllBills() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                            </Button></Tooltip>
                        {/* <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                classes={{ main: 'over_all_bill' }}
                                columns={this.state.isPharmacy ? PharmacyColumns : columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <BillingsFilter PageName={"OverallBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} Pharmacy={this.state.isPharmacy} callDocApi={true}/>
                </Drawer>
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                            URL={Serviceurls.INVOICE_PRINT}
                            Title={t("BillPreview")}
                            BillData={this.state.SelectedBill}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            ModifyBillButton={CheckAccessFunc("front_office", "Billing Transaction", 'Overall Bills', null, "Tab")?.editAccess ? (((this.state.SelectedBill.bill_type !== "credit" && this.state.SelectedBill.bill_type !== "cancelled" && (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient"))) ? true : false) : false}
                            cancelBillButton={CheckAccessFunc("front_office", "Billing Transaction", 'Overall Bills', null, "Tab")?.editAccess ? ((this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") && this.state.SelectedBill.bill_type !== "cancelled") : false}
                            PrintButton={false}
                            history={this.props.history} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {
                    Object.keys(this.state.BillModifyHistoryItem).length > 0 ? <EditHistoryPopup BillType={this.state.BillModifyHistoryItem?.bill_types} BillId={this.state.BillModifyHistoryItem?.id} SelectedBill={this.state.BillModifyHistoryItem} HistoryPopClose={this.HistoryPopClose.bind(this)} /> : null
                }
            </Box>
        )
    }
}

export default withTranslation()(OverAllBills)

